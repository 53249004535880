@import "../../theme";

$color-size: 24px;

.list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 6px;
  list-style: none;
}

.item {
  height: $color-size + 2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.color {
  width: $color-size;
  height: $color-size;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  box-sizing: border-box;
}

.color.selected {
  padding: 2px;
  border: 1px solid transparent;
  background: $primary-white;
}

.color:not(.selected) {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.color-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.color-inner.transparent {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid $primary-grey-400;

  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 1px;
    height: 100%;

    content: "";
    transform: translate(-50%, -50%) rotate(45deg);

    background-color: $primary-grey-400;
  }
}
