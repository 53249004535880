/** @prettier */

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.wrapper span {
  line-height: normal;
}
