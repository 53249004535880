/** @prettier */
// Button transition
$btn-transition: 250ms ease-in-out;
$border-color-transition: border-color 300ms ease-in;

@mixin animation(
  $type,
  $duration-in-seconds,
  $timing-function: ease,
  $iteration-count: 1,
  $fill-mode: none
) {
  -webkit-animation: $type #{$duration-in-seconds}s $timing-function
    $iteration-count $fill-mode;
  -moz-animation: $type #{$duration-in-seconds}s $timing-function
    $iteration-count $fill-mode;
  -ms-animation: $type #{$duration-in-seconds}s $timing-function
    $iteration-count $fill-mode;
  -o-animation: $type #{$duration-in-seconds}s $timing-function $iteration-count
    $fill-mode;
  animation: $type #{$duration-in-seconds}s $timing-function $iteration-count
    $fill-mode;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-10-1 13:57:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
