@use "ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;
@import "ui/dsl/_settings/fonts";

:root {
  --font-family: #{variables.$primary-font-family};
  --color-primary: #{color.$primary-ph-blue};
  --color-primary-dark: #{color.$primary-dark-blue};
  --color-primary-light: #{color.$primary-powder-blue-light};
}

body {
  margin: 0;
  font-family: var(--font-family), Roboto, Arial, sans-serif, serif;
  font-weight: 350;
  color: color.$primary-rich-blue;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}
