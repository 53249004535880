@use "sass:math";

@import "../../_theme.scss";

$container-size: 30px;
$arrow-size: $container-size * 0.5;
$arrow-from-edge-spacing: math.div($container-size, 1.5);

// on the design, the arrows aren't perfectly symetrical
$scale-factor: 0.85;

$additional-tooltip-spacing: 5px;
$total-tooltip-spacing: $arrow-size + $additional-tooltip-spacing;

@mixin transformWithScaleX($initialTransformation) {
  transform: $initialTransformation scaleX($scale-factor);
}

@mixin transformWithScaleY($initialTransformation) {
  transform: $initialTransformation scaleY($scale-factor);
}

.default {
  position: relative;
  display: none;

  max-width: 440px;

  @include breakpoint-mobile {
    max-width: unset;
  }
  composes: body--15-350 from "../../theme/typography.module.scss";
}

.open {
  display: block;
}

.wrapped {
  position: absolute;
  z-index: 8;

  :global(.body-modal-opened) & {
    z-index: #{$index-modal + 1};
  }

  &.direction-top {
    &.placement-left {
      transform: translate(0, calc(-100% - #{$total-tooltip-spacing}));
    }
    &.placement-center {
      transform: translate(-50%, calc(-100% - #{$total-tooltip-spacing}));
    }
    &.placement-right {
      transform: translate(-100%, calc(-100% - #{$total-tooltip-spacing}));
    }
  }

  &.direction-bottom {
    &.placement-left {
      transform: translate(0, #{$total-tooltip-spacing});
    }
    &.placement-center {
      transform: translate(-50%, #{$total-tooltip-spacing});
    }
    &.placement-right {
      transform: translate(-100%, #{$total-tooltip-spacing});
    }
  }

  &.direction-left {
    &.placement-top {
      transform: translate(calc(-100% - #{$total-tooltip-spacing}), 0);
    }
    &.placement-center {
      transform: translate(calc(-100% - #{$total-tooltip-spacing}), -50%);
    }
    &.placement-bottom {
      transform: translate(calc(-100% - #{$total-tooltip-spacing}), -100%);
    }
  }

  &.direction-right {
    &.placement-top {
      transform: translate(#{$total-tooltip-spacing}, 0);
    }
    &.placement-center {
      transform: translate(#{$total-tooltip-spacing}, -50%);
    }
    &.placement-bottom {
      transform: translate(#{$total-tooltip-spacing}, -100%);
    }
  }
}

.full {
  width: 100%;
}

.content {
  color: $primary-rich-blue;
  background-color: $primary-white;
  border: solid 1px $primary-grey-400;
  margin: 0;
  z-index: 2;
  border-radius: 5px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  .small & {
    width: auto;
  }
}

.arrow {
  position: absolute;
  display: block;
  width: $container-size;
  height: $container-size;
  transform-origin: center;
}

.arrow:after,
.arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.arrow {
  &.align-right {
    left: initial;
    right: $arrow-from-edge-spacing;
  }

  &.align-left {
    right: initial;
    left: $arrow-from-edge-spacing;
  }

  &.align-top {
    bottom: initial;
    top: $arrow-from-edge-spacing;
  }

  &.align-bottom {
    bottom: $arrow-from-edge-spacing;
    top: initial;
  }

  &.direction-right {
    left: 0;

    &.align-center {
      top: 50%;
      @include transformWithScaleY(translateX(-50%) translateY(-50%));
    }

    &.align-top,
    &.align-bottom {
      @include transformWithScaleY(translateX(-50%));
    }

    &:before,
    &:after {
      border-width: $arrow-size $arrow-size $arrow-size 0;
    }

    &:before {
      left: 0;
      border-right-color: $primary-grey-400;
    }

    &:after {
      left: 1px;
      border-right-color: $primary-white;
    }
  }

  &.direction-left {
    right: 0;

    &.align-center {
      top: 50%;
      @include transformWithScaleY(translateX(50%) translateY(-50%));
    }

    &.align-top,
    &.align-bottom {
      @include transformWithScaleY(translateX(50%));
    }

    &:before,
    &:after {
      border-width: $arrow-size 0 $arrow-size $arrow-size;
    }

    &:before {
      right: 0;
      border-left-color: $primary-grey-400;
    }
    &:after {
      right: 1px;
      border-left-color: $primary-white;
    }
  }

  &.direction-top {
    bottom: 1px;

    &.align-center {
      @include transformWithScaleX(translateX(-50%) translateY(50%));
      left: 50%;
    }

    &.align-right,
    &.align-left {
      @include transformWithScaleX(translateY(50%));
    }

    &:before,
    &:after {
      border-width: $arrow-size $arrow-size 0;
    }

    &:before {
      bottom: 0;
      border-top-color: $primary-grey-400;
    }
    &:after {
      bottom: 1px;
      border-top-color: $primary-white;
    }
  }

  &.direction-bottom {
    top: 0;

    &.align-center {
      @include transformWithScaleX(translateX(-50%) translateY(-50%));
      left: 50%;
    }

    &.align-right,
    &.align-left {
      @include transformWithScaleX(translateY(-50%));
    }

    &:before,
    &:after {
      border-width: 0 $arrow-size $arrow-size $arrow-size;
    }

    &:before {
      top: 0;
      border-bottom-color: $primary-grey-400;
    }
    &:after {
      top: 1px;
      border-bottom-color: $primary-white;
    }
  }
}
