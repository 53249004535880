@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  composes: (header--32-400);

  display: grid;
  gap: 16px;
  place-items: center;
  text-align: center;
}

.wrapper svg use {
  fill: color.$primary-grey-400;
}
