@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
