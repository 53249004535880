@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.label {
  composes: (body-uc--13-400);

  display: block;
  margin-bottom: 8px;
  color: color.$primary-grey-500;
}
