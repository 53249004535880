@use "~dsl/src/theme/_color.scss" as color;

.toggle {
  width: 24px;
  height: 24px;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid var(--color-primary, color.$primary-ph-blue);
  background-color: transparent;
  flex-shrink: 0;
}

.toggle.oval {
  border-radius: 50%;
}

.toggle.rounded {
  border-radius: 2px;
}

.toggle:disabled {
  background: none;
}

.toggle:not(:disabled):hover {
  cursor: pointer;
  border: 1px solid color.$primary-rich-blue;
}

.color {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.color img {
  width: 100%;
}

.toggle.oval .color {
  border-radius: 50%;
}

.color.empty {
  position: relative;
  border: 1px solid color.$primary-grey-400;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 1px;
    height: 100%;

    content: "";
    transform: translate(-50%, -50%) rotate(45deg);

    background-color: color.$primary-grey-400;
  }
}
