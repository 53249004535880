@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  display: grid;
  gap: 8px;
}

.content {
  display: grid;
  gap: 6px;
}

.content p {
  margin: 0;
}

.title {
  composes: (header--17-400);

  margin: 0;
  color: color.$primary-rich-blue;
}
