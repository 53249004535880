@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.item {
  composes: (body--15-350);

  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  border: 0;
  background: none;
  box-sizing: border-box;
  cursor: pointer;
  color: color.$primary-rich-blue;
  text-decoration: none;
  white-space: nowrap;
  line-height: normal;
  transition: variables.$hover-transition;
}

.item:hover {
  background-color: color.$primary-grey-100;
  color: var(--color-primary, color.$primary-ph-blue);
}

.item svg {
  margin-right: 12px;
}

.item svg use {
  fill: color.$primary-grey-600;
}

.item:hover svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}
