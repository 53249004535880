@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  composes: (body--13-350);

  position: fixed;
  padding: 4px 8px;
  border-radius: variables.$border-radius;
  background-color: rgba(0, 6, 26, 0.7);
  color: color.$primary-white;
  white-space: nowrap;
  z-index: map-get(variables.$z-index, tooltip);
}
