@use "../../../_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  width: 100vw;
  height: calc(100vh - 72px);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 248, 248, 0.8);
  z-index: map-get(variables.$z-index, sceneOverlay);
}

.wrapper > div {
  max-width: 316px;
  box-shadow: variables.$box-shadow;
  background-color: color.$primary-white;
}
