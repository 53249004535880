@use "~dsl/src/theme/_spacing.scss" as spacing;
@use "~dsl/src/theme/_color.scss" as color;

$item-width: 31px;
$inset: 2px;
.list {
  padding-inline-start: 0;
  display: flex;
  justify-content: space-between;
  margin-block-start: calc(#{spacing.$spacing-s2} - #{$inset});
  margin-block-end: spacing.$spacing-s2;
  // hacky because of :after larger than content, looks good, can fix later
  margin-left: -$inset;
  margin-right: $inset;

  > li {
    display: block;
    height: fit-content;
    width: $item-width;
    height: $item-width;
    margin: 1px;
  }
}

.groupItem {
  padding: 0;
  margin: $inset;
  width: $item-width;
  height: $item-width;
  border-radius: $inset;
  cursor: pointer;
  border: solid calc(#{$inset}/ 2) white;
  outline: 0 solid transparent;

  &:after {
    content: "";
    display: block;
    position: relative;
    width: $item-width;
    height: $item-width;
    top: -$inset;
    left: -$inset;
    border-radius: $inset;
    border: calc(#{$inset}/ 2) solid rgba(47, 47, 47, 0.1);
  }

  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    &:after {
      border: calc(#{$inset}/ 2) solid
        var(--color-primary, color.$primary-ph-blue);
    }
  }

  &:hover {
    box-shadow: 0px 0px calc(#{$inset}/ 2) calc(#{$inset}/ 2)
      rgba(47, 47, 47, 0.3);
  }
}
.groupItemSelected {
  &:after {
    border: calc(#{$inset}/ 2) solid
      var(--color-primary, color.$primary-ph-blue);
  }
}
