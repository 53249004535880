@import "../../_theme.scss";
$size-width-text-input-wrapper: 82px;

.item-container {
  width: 100%;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  min-height: $spacing-s6;
}

.item-footer {
  float: right;
  padding-right: $spacing-s6;
  font-size: $font-size-13;
  color: $primary-grey-500;
}

.separator {
  position: relative;
  top: $spacing-s2;

  > hr {
    background-color: #f5f5f5;
  }
}

.footer-banner {
  margin-top: $spacing-s2;
}

.header-banner {
  margin-bottom: $spacing-s2;
}

.header__inner {
  padding-top: $spacing-s1;
  padding-bottom: $spacing-s1;
  padding-right: $spacing-s4;
}

.header__text {
  display: inline-block;
  padding-right: $spacing-s2;
}

.out-of-the-box__inner {
  display: flex;
  justify-content: space-between;

  padding-right: $spacing-s4;
}

.out-of-the-box__text {
  display: inline-block;
  padding-right: $spacing-s2;
}

.out-of-the-box__link {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  text-decoration: underline;

  color: $primary-white;
}

.search-text-input {
  display: flex;

  width: 100%;
}

.selected-item-details {
  display: flex;
  align-items: center;

  position: absolute;
  right: #{$spacing-s4 * 2};
  top: 0;

  height: 100%;
}

.selected-item-indicator {
  position: absolute;
  right: 12px;
}

.actions-wrap {
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(#{$spacing-s6} + #{$spacing-s2});
}

.oldprice {
  margin-left: 5px;
  color: $primary-grey-500;
  text-decoration: line-through;
}
