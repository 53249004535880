/* WARNING, ACHTUNG! AUTOGENERATED FILE */
/* Your changes to this file will perish */
@use "sass:math";

/* Header typography modifiers */
.header--64-500 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.04;
  letter-spacing: -0.06em;
}

.header--56-400 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.04;
  letter-spacing: -0.06em;
}

.header--48-400 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.06em;
}

.header--40-500 {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: -0.05em;
}

.header--40-400 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.05em;
}

.header--32-400 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.05em;
}

.header--24-400 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.04em;
}

.header--20-500 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--20-400 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--17-500 {
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--17-400 {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--15-400 {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--15-500 {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--13-400 {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--13-500 {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.02em;
}

.header--64-500,
.header--56-400,
.header--48-400,
.header--40-500,
.header--40-400,
.header--32-400,
.header--24-400,
.header--20-500,
.header--20-400,
.header--17-500,
.header--17-400,
.header--15-400,
.header--15-500,
.header--13-400,
.header--13-500 {
  font-family: var(--font-family, ABCFavoritVariable), ABCFavoritVariable,
    Roboto, Arial, sans-serif, serif;
  font-feature-settings: "clig" 0, "liga" 0;
  font-style: normal;
}

/* Body typography modifiers */
.body--32-350 {
  font-size: 2rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.04em;
}

.body--24-400 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--24-350 {
  font-size: 1.5rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--20-350 {
  font-size: 1.25rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--17-350 {
  font-size: 1.0625rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--15-350 {
  font-size: 0.9375rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--13-350 {
  font-size: 0.8125rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--11-350 {
  font-size: 0.6875rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body--10-350 {
  font-size: 0.625rem;
  font-weight: 350;
  line-height: 1.32;
  letter-spacing: -0.02em;
}

.body-uc--15-500 {
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.body-uc--13-400 {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.body-uc--11-400 {
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.body-uc--10-400 {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.08;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.body--32-350,
.body--24-400,
.body--24-350,
.body--20-350,
.body--17-350,
.body--15-350,
.body--13-350,
.body--11-350,
.body--10-350,
.body-uc--15-500,
.body-uc--13-400,
.body-uc--11-400,
.body-uc--10-400 {
  font-family: var(--font-family, ABCFavoritVariable), ABCFavoritVariable,
    Roboto, Arial, sans-serif, serif;
  font-feature-settings: "clig" 0, "liga" 0;
  font-style: normal;
}

@media (max-width: 992px) {
  /* Mobile Header typography modifiers */
  .mobile-header--64-500 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.04;
    letter-spacing: -0.06em;
  }

  .mobile-header--56-400 {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.04;
    letter-spacing: -0.06em;
  }

  .mobile-header--48-400 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.06em;
  }

  .mobile-header--40-500 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: -0.05em;
  }

  .mobile-header--40-400 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.05em;
  }

  .mobile-header--32-400 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.05em;
  }

  .mobile-header--24-400 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.16;
    letter-spacing: -0.04em;
  }

  .mobile-header--20-500 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--20-400 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--17-500 {
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--17-400 {
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--15-400 {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--15-500 {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--13-400 {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  .mobile-header--13-500 {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.16;
    letter-spacing: -0.02em;
  }

  /*Mobile Body typography modifiers */
  .mobile-body--32-350 {
    font-size: 2rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.04em;
  }

  .mobile-body--24-400 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--24-350 {
    font-size: 1.5rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--20-350 {
    font-size: 1.25rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--17-350 {
    font-size: 1.0625rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--15-350 {
    font-size: 0.9375rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--13-350 {
    font-size: 0.8125rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--11-350 {
    font-size: 0.6875rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body--10-350 {
    font-size: 0.625rem;
    font-weight: 350;
    line-height: 1.32;
    letter-spacing: -0.02em;
  }

  .mobile-body-uc--15-500 {
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.08;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  .mobile-body-uc--13-400 {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  .mobile-body-uc--11-400 {
    font-size: 0.6875rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  .mobile-body-uc--10-400 {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.08;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }
}
