@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.wrapper {
  width: 164px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: variables.$border-radius--l;
  border: none;
  background-color: color.$primary-white;
  box-shadow: variables.$box-shadow;
  z-index: map-get(variables.$z-index, cameraControls);
}

.wrapper img {
  width: 100%;
}

.button svg use {
  fill: color.$primary-white;
}
