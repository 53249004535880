@import "../../_theme.scss";
$input-medium-padding: 11px $spacing-s3;

.base {
  font-size: $font-size-15;
  line-height: $line-height-132;
  font-family: $font-family-default;
  font-weight: $font-weight-regular;

  overflow: hidden;

  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: $spacing-s2 $spacing-s3;

  white-space: nowrap;

  color: $primary-rich-blue;
  border: 1px solid $primary-grey-300;
  border-radius: 4px;
  background-color: $primary-white;

  appearance: none;
  caret-color: $primary-ph-blue; // Edge doesn't support it @fixme?

  &::placeholder {
    color: $primary-grey-500;
  }

  &:focus {
    border-color: $primary-ph-blue;
    outline: none;
    background-color: $primary-white;
    box-shadow: 0 0 4px 0 $primary-ph-blue;

    caret-color: $primary-ph-blue; // Edge doesn't support it @fixme?
  }

  &:disabled {
    color: $primary-grey-500;
    background-color: $primary-grey-100;
  }

  @include breakpoint-mobile {
    font-size: $font-size-17; // ifx iOS zoom on fields
  }
}

.base[type="number"]::-webkit-outer-spin-button,
.base[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.base[type="number"] {
  -moz-appearance: textfield;
}

.base--is-typing:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.error {
  border-color: $primary-red;
  background-color: $primary-red-light;

  caret-color: $primary-red; // Edge doesn't support it @fixme?
}

.handicapped {
  border: none;
}

.medium {
  padding: $input-medium-padding;
}

.medium {
  padding: $input-medium-padding;
}
