.scene {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.scene.hidden {
  opacity: 0;
  pointer-events: none;
}
