@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.picker {
  composes: (body--15-350);

  height: 48px;
  padding: 14px 16px;
  border: 1px solid color.$primary-grey-300;
  border-radius: variables.$border-radius;
  background-color: color.$primary-white;
  transition: variables.$hover-transition;
}

.picker:not(.active):disabled {
  color: color.$primary-grey-400;
}

.picker.active {
  color: color.$primary-rich-blue;
  border-color: var(--color-primary, color.$primary-ph-blue);
}

.picker:not(:disabled):hover {
  border-color: var(--color-primary, color.$primary-ph-blue);
  cursor: pointer;
}
