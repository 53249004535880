.item {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}

.item * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
