@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper.inline {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.label {
  composes: (header--15-400);

  display: block;
  margin-bottom: 8px;
}

.wrapper.inline .label {
  width: 50%;
  margin-bottom: 0;
}

.content {
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  flex-grow: 1;
}

.content.vertical {
  grid-auto-flow: row;
}

.wrapper.inline .content > :first-child {
  margin: 0 0 0 auto;
}
