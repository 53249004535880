@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  display: grid;
  gap: 16px;
  place-items: center;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
}

.wrapper.small {
  gap: 8px;
}
