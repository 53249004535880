@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.tooltip-wrapper {
  position: relative;
  display: flex;
}

.tooltip-wrapper > *:hover + .tooltip {
  display: block;
}

.tooltip {
  composes: (body--11-350);

  display: none;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translate(-50%, 100%);
  padding: 4px 8px;
  border-radius: variables.$border-radius;
  background-color: rgba(0, 6, 26, 0.7);
  color: color.$primary-white;
  white-space: nowrap;
  z-index: map-get(variables.$z-index, tooltip);
}
