@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  width: 20px;
  height: 20px;
  display: inline-block;
  flex-shrink: 0;
}

.wrapper.s {
  width: variables.$icon-size--s;
  height: variables.$icon-size--s;
}

.wrapper.xs {
  width: variables.$icon-size--xs;
  height: variables.$icon-size--xs;
}

.wrapper.l {
  width: variables.$icon-size--l;
  height: variables.$icon-size--l;
}

.wrapper.xl {
  width: variables.$icon-size--xl;
  height: variables.$icon-size--xl;
}

.wrapper use {
  fill: color.$primary-rich-blue;
}

.wrapper-inline {
  display: inline-block;
}

.wrapper-inline svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.wrapper-inline.s svg {
  width: variables.$icon-size--s;
  height: variables.$icon-size--s;
}

.wrapper-inline.xs svg {
  width: variables.$icon-size--xs;
  height: variables.$icon-size--xs;
}

.wrapper-inline.l svg {
  width: variables.$icon-size--l;
  height: variables.$icon-size--l;
}

.wrapper-inline.xl svg {
  width: variables.$icon-size--xl;
  height: variables.$icon-size--xl;
}

.wrapper-inline svg {
  fill: color.$primary-rich-blue;
}
