@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.wrapper {
  position: absolute;
  pointer-events: all;
  background-color: color.$primary-white;
  padding: 4px;
  border-radius: variables.$border-radius;
  box-shadow: variables.$box-shadow;
}

.wrapper.top {
  transform: translate(-50%, calc(-100% - 24px));
}

.wrapper.bottom {
  transform: translate(-50%, 24px);
}

.wrapper > nav button {
  margin: 0;
}

.dropdown {
  position: absolute;
  right: 0;
  transform: translate(calc(100% - 36px), 100%);
}

.wrapper.top .dropdown {
  bottom: -4px;
  transform: translate(calc(100% - 36px), 100%);
}

.wrapper.bottom .dropdown {
  top: -4px;
  transform: translate(calc(100% - 36px), -100%);
}

.wrapper svg use {
  fill: color.$primary-rich-blue;
}
