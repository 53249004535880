@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.check-info-box {
  padding: 16px;
  border-radius: variables.$border-radius;
  background-color: color.$primary-grey-100;
}

.label {
  composes: (header--15-400);

  line-height: normal;
}

.description {
  composes: (body--13-350);

  margin: 8px 0 0 32px;
  color: color.$primary-grey-600;
}
