@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  width: 100%;
  position: relative;
}

.tile {
  width: 100%;
  display: block;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  padding: 0;
  background: none;
  border: 1px solid transparent;
  transition: variables.$hover-transition;
}

.wrapper:hover .tile:not(:disabled) {
  border-color: var(--color-primary, color.$primary-ph-blue);
  cursor: pointer;
}

.tile.active {
  color: color.$primary-rich-blue;
  border-color: var(--color-primary, color.$primary-ph-blue);
}

.tile:not(.active):disabled {
  color: color.$primary-grey-400;
}

.square {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 100%;
  background-color: color.$primary-grey-100;
  border: 0;
  border-radius: variables.$border-radius;
}

.content {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content figure,
.content figure > :not(img):not(svg) {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content figure img,
.content figure svg {
  max-width: 100%;
  max-height: 100%;
}

.content.monochromatic > * {
  filter: brightness(0);
}

.label {
  composes: (body--13-350);

  padding: 8px;
}

.menu {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  background-color: color.$primary-white;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.menu:hover {
  background-color: color.$primary-grey-200;
}

.tile:hover .menu,
.tile.active .menu {
  top: 3px;
  right: 3px;
}

.badge {
  position: absolute;
  top: 8px;
  left: 8px;
}

.badge span {
  margin: 0;
}
