@use "~dsl/src/theme/_color.scss" as color;

$padding: 100px;

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: $padding;
  background-color: color.$primary-grey-100;
}

.ui {
  width: 1024px;
  height: 1024px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  pointer-events: none;
}

.wrapper.space .ui {
  max-width: calc(100% - #{2 * $padding});
  max-height: calc(100% - #{2 * $padding});
}
