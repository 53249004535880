@use "sass:math";

@import "../../_theme.scss";

$border-width: 0px;
$border-radius: 4px;

$button-padding-large: 0 32px;
$button-padding-medium: 16px;
$button-padding-small: 12px 16px;

$button-padding-large-mobile: 16px;
$button-padding-large-icon: 16px;

@mixin small() {
  font-family: $font-family-default;
  font-size: $font-size-15;
  line-height: $line-height-116;
  font-weight: $font-weight-medium;
  letter-spacing: $letter-spacing-negative-2;

  height: 32px;
  padding: $button-padding-small;

  .label,
  .icon-wrapper {
    padding: $button-padding-small;
  }
}

@mixin medium() {
  font-family: $font-family-default;
  font-size: $font-size-17;
  line-height: $line-height-116;
  font-weight: $font-weight-medium;
  letter-spacing: $letter-spacing-negative-2;

  height: 48px;
  padding: $button-padding-medium;

  .label,
  .icon-wrapper {
    padding: $button-padding-medium;
  }
}

@mixin large() {
  font-family: $font-family-default;
  font-size: $font-size-20;
  line-height: $line-height-116;
  font-weight: $font-weight-medium;
  letter-spacing: $letter-spacing-negative-2;

  height: 60px;
  padding: $button-padding-large;

  border-radius: $border-radius;

  .label {
    padding: $button-padding-large;
  }
  .icon-wrapper {
    padding: $button-padding-large-icon;
  }
}

.loader-icon {
  margin-right: $spacing-s2;
  display: inline-flex;
  align-items: center;
}

.base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  box-sizing: border-box;
  width: fit-content;
  padding: 0;

  cursor: pointer;
  transition: $btn-transition;
  text-decoration: none;

  color: $primary-white;
  border: solid $border-width transparent;
  border-radius: $border-radius;
  background-color: var(--color-primary, $primary-ph-blue);

  &.loading {
    user-select: none;
    pointer-events: none;
  }

  &:active {
    color: $primary-white;
    background-color: var(--color-primary-dark, $primary-dark-blue);
  }

  &:hover,
  &.loading {
    background-color: var(--color-primary-dark, $primary-dark-blue);
  }

  &:focus:not([disabled]) {
    color: $primary-white;
    background-color: var(--color-primary-dark, $primary-dark-blue);
    outline: none;
  }

  &[disabled] {
    user-select: none;
    pointer-events: none;

    color: $primary-grey-500;
    background-color: $primary-grey-400;
    .icon-wrapper {
      background-color: $primary-grey-400;
    }
  }
  svg {
    width: 1em;
    height: 1em;

    font-size: $font-size-17;

    fill: currentColor;
  }
}

.fluid {
  width: 100%;
  justify-content: center;
}

.fluid-mobile {
  @include breakpoint-mobile {
    width: 100%;
    justify-content: center;
  }
}

.large {
  @include large;
}

.medium {
  @include medium;
}

.small {
  @include small;
}

.with-icon {
  &.large,
  &.medium,
  &.small {
    padding: 0;
  }
  &.fluid {
    justify-content: initial;
  }
}

.secondary {
  color: $primary-rich-blue;
  background-color: rgba($primary-rich-blue, 0.05);

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]) {
    color: $primary-rich-blue;
    background-color: rgba($primary-rich-blue, 0.2);
  }

  &.loading {
    background-color: transparent;
    box-shadow: unset;
  }
}

.secondary-white {
  padding: 12px $spacing-s3;
  font-family: $font-family-default;
  color: $primary-white;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]) {
    color: $primary-white;
    background-color: var(--color-primary-dark, $primary-dark-blue);
  }
}

.secondary-mariner {
  padding: 12px $spacing-s3;
  font-family: $font-family-default;
  background-color: var(--color-primary-dark, $primary-dark-blue);
  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]) {
    color: $primary-rich-blue;
    background-color: transparent;
  }
}

.secondary-white-three {
  font-family: $font-family-default;
  color: $primary-rich-blue;
  border-color: $primary-rich-blue;
  background-color: $primary-white;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]) {
    color: $primary-white;
    background-color: var(--color-primary-dark, $primary-dark-blue);
  }
}

.supporting-transparent {
  color: $primary-rich-blue;
  background-color: transparent;
  align-items: center;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]),
  &.loading {
    color: $primary-rich-blue;
    background-color: $primary-white;
    box-shadow: none;
    outline: transparent;
  }
}

.semi-transparent {
  color: $primary-rich-blue;
  background-color: rgba(0, 6, 26, 0.05);
  align-items: center;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]),
  &.loading {
    color: $primary-rich-blue;
    background-color: rgba(0, 6, 26, 0.2);
    box-shadow: none;
    outline: transparent;
  }
}

.destructive {
  background-color: $primary-red;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible,
  &:focus:not([disabled]),
  &.loading {
    background-color: $primary-red;
  }
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  flex-grow: 1;
}

.icon-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  background-color: var(--color-primary-dark, $primary-dark-blue);

  .destructive & {
    background-color: $primary-red;
  }

  .secondary & {
    background-color: transparent;
  }
}

.button-wrapper {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  .left-icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: $spacing-s2;
  }
}

@include breakpoint-mobile {
  .mobile-fluid {
    justify-content: center;

    width: 100%;

    text-align: center;
  }
}

@include breakpoint-mobile {
  .base.mobile- {
    &small {
      @include small;
    }
    &medium {
      @include medium;
    }
    &large {
      @include large;
    }
  }

  .fluid,
  .fluid-mobile {
    &.large {
      padding: $button-padding-large-mobile;
    }
  }
}
