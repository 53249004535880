@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.wrapper svg use {
  fill: color.$primary-grey-500;
  cursor: default;
}

.popover {
  padding: 16px;
}
