@use "~dsl/src/theme/_color.scss" as color;

.proscenium {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(96px, auto) 1fr;
  grid-template-rows: 72px calc(100vh - 72px);
  grid-template-areas:
    "header header"
    "aside scene";
  background-color: color.$primary-grey-100;
}

.proscenium.read-only {
  grid-template-areas:
    "header header"
    "scene scene";
}

.header {
  grid-area: header;
}

.aside {
  grid-area: aside;
}

.scene {
  grid-area: scene;
  position: relative;
}

.hidden {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
