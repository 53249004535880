@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.dropzone {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: variables.$border-radius;
  border: 1px dashed var(--color-primary, color.$primary-ph-blue);
  background-color: color.$primary-grey-100;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.dropzone:not(.disabled):hover {
  background-color: var(
    --color-primary-light,
    color.$primary-powder-blue-light
  );
}

.dropzone.disabled {
  border: none;
  background-color: color.$primary-white;
}

.state {
  composes: (body--15-350);

  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  line-height: normal;
  color: color.$primary-grey-600;
}

.state.initial {
  text-decoration: underline;
}

.state.error {
  color: color.$primary-red;
}

.state svg,
.state .loader {
  width: variables.$icon-size;
  height: variables.$icon-size;
}

.state.initial svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.state.error svg use {
  fill: color.$primary-red;
}

.preview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: variables.$border-radius;
  border: 1px solid color.$primary-grey-300;
  overflow: hidden;
  transition: variables.$hover-transition;
}

.preview:hover {
  border-color: var(--color-primary, color.$primary-ph-blue);
}

.preview > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.preview img {
  max-width: 80%;
  max-height: 80%;
  display: block;
  margin: 0 auto;
}
