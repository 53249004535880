@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";
@use "../../_settings/variables" as variables;

.item {
  composes: (body--15-350);

  padding: 0;
  border: 0;
  background: none;
  color: color.$primary-grey-600;
  text-decoration: underline;
  text-align: left;
  line-height: normal;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.item:hover {
  color: color.$primary-rich-blue;
}
