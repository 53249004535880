@use "src/ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;

.item {
  position: relative;
  margin: 0 8px;
  padding: 8px;
  border: 0;
  border-radius: variables.$border-radius;
  background-color: transparent;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.item:disabled {
  cursor: default;
}

.item.active,
.item:not(:disabled):hover {
  background-color: color.$primary-grey-200;
}

.icon {
  width: variables.$icon-size;
  height: variables.$icon-size;
  position: relative;
}

.icon.s {
  width: variables.$icon-size--s;
  height: variables.$icon-size--s;
}

.icon.xs {
  width: variables.$icon-size--xs;
  height: variables.$icon-size--xs;
}

.icon.l {
  width: variables.$icon-size--l;
  height: variables.$icon-size--l;
}

.icon.xl {
  width: variables.$icon-size--xl;
  height: variables.$icon-size--xl;
}

.icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.icon div:first-child svg {
  visibility: visible;
}

// start: for WL purpose we need div container
.icon div:last-child:not(:first-child) svg {
  visibility: hidden;
}

.item:not(:disabled):hover .icon div:first-child svg,
.item:not(:disabled).active .icon div:first-child svg {
  visibility: hidden;
}

.item:not(:disabled):hover .icon div:last-child svg,
.item:not(:disabled).active .icon div:last-child svg:last-child {
  visibility: visible;
}
// finish: for WL purpose we need div container

.icon svg:first-child {
  visibility: visible;
}

.icon svg:last-child:not(:first-child) {
  visibility: hidden;
}

.item:not(:disabled):hover .icon svg:first-child,
.item:not(:disabled).active .icon svg:first-child {
  visibility: hidden;
}

.item:not(:disabled):hover .icon svg:last-child,
.item:not(:disabled).active .icon svg:last-child {
  visibility: visible;
}

.item .icon svg use {
  fill: color.$primary-grey-600;
}

.item:disabled .icon svg use {
  fill: color.$primary-grey-300;
}

.item:not(:disabled):hover .icon svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.item:not(:disabled).active .icon svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.state-icon {
  position: absolute;
  right: 2px;
  bottom: -2px;
}

.state-icon > svg use {
  fill: color.$primary-grey-400;
}
