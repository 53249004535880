/* WARNING, ACHTUNG! AUTOGENERATED FILE */
/* Your changes to this file will perish */
@use "sass:math";

// Font Family Default
$font-family-default: var(--font-family, ABCFavoritVariable), ABCFavoritVariable,
  Roboto, Arial, sans-serif, serif;

// Color
$font-color-black: #00061a;
$font-color-black-transparent: rgba(#00061a, 0.7);
$font-color-white: #ffffff;
$font-color-white-transparent: rgba(#ffffff, 0.7);
$font-color-blue: #2757ff;
$font-color-red: #8a140c;
$font-color-grey-500: #86868b;
$font-color-grey-600: #555555;

// Size
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-13: 13px;
$font-size-15: 15px;
$font-size-17: 17px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-48: 48px;
$font-size-56: 56px;
$font-size-64: 64px;

// Line Height
$line-height-104: 1.04;
$line-height-108: 1.08;
$line-height-116: 1.16;
$line-height-120: 1.2;
$line-height-124: 1.24;
$line-height-132: 1.32;
$line-height-140: 1.4;

// Font Weight
$font-weight-book: 350;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Letter Spacing
$letter-spacing-negative-2: -0.02em;
$letter-spacing-negative-4: -0.04em;
$letter-spacing-negative-5: -0.05em;
$letter-spacing-negative-6: -0.06em;
