@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  composes: (body--15-350);

  display: grid;
  gap: 10px;
  padding: 12px 16px;
  border-radius: variables.$border-radius;
  background-color: color.$primary-grey-100;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pickers {
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  place-items: center;
}
