@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.product-info {
  height: 48px;
  padding: 0 16px 0 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid color.$primary-grey-300;
  border-radius: variables.$border-radius;
  background-color: color.$primary-white;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.product-info:hover {
  background-color: color.$primary-grey-100;
}

.product-info img {
  height: 100%;
  display: block;
}

.description {
  composes: (body--13-350);

  margin-left: 8px;
  text-align: left;
}

.quantity {
  display: block;
  color: color.$primary-grey-600;
}

.price {
  composes: (header--17-400);

  margin-left: 24px;
}
