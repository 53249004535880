/** @prettier */
@use "../../../../theme/color";

$indicator-width: 32px;
$icon-size: 24px;

.icon {
  width: $icon-size;
  height: $icon-size;
}

.indicator {
  width: $indicator-width;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
