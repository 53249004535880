/** @prettier */
@use "../../../../../theme/spacing";
@use "../../../../../theme/color";

$size-price-width: 70px;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: spacing.$spacing-s2;

  [e2e-target="badge"] {
    margin: 0;
  }
}

.piece-price {
  color: color.$primary-grey-500;
}

.price {
  width: $size-price-width;
  text-align: right;

  &.with-promo {
    text-decoration: line-through;
  }
}
