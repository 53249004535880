/** @prettier */
@use "../../../../theme/spacing";
@use "../../../../theme/color";

$header-height: 56px;

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-background-grey {
    background-color: color.$primary-grey-100;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  padding: 0 spacing.$spacing-s3;
  border-bottom: color.$primary-grey-400 1px solid;
  flex: 0 0 auto;

  &-border-none {
    border: none;
  }
}

.footer {
  border-top: color.$primary-grey-400 1px solid;
}
