@import "../../_theme.scss";
@import "../BaseInput/BaseInput.module.scss";

@keyframes pulsating-glow {
  0% {
    border-color: $primary-ph-blue;
    box-shadow: 0 0 4px $primary-ph-blue;
  }
  100% {
    border-color: $primary-grey-300;
    box-shadow: 0 0 20px $primary-white;
  }
}

.textfield {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: relative;
  z-index: 2;
}

.affix {
  composes: body--15-350 from "../../theme/typography.module.scss";

  display: flex;
  align-items: center;

  height: 100%;
  box-sizing: border-box;
}

.clickable {
  cursor: pointer;

  .disabled & {
    pointer-events: none;
    cursor: default;
  }
}

.prefix {
  padding: $spacing-s2;
  margin-left: 1px;

  color: $primary-grey-500;

  background-color: $primary-grey-100;
}

.suffix {
  padding: 0 $spacing-s3 0 0;
  margin-right: 1px;

  color: $primary-grey-500;

  text-align: right;

  order: 1;

  svg {
    width: 1em;
    height: 1em;
    fill: $primary-grey-400;
  }
}

.error:not(.disabled):not(.focused) {
  .input-mock {
    background-color: $primary-red-light;
    border-color: $primary-red;
  }
  .prefix {
    background-color: $primary-red;

    color: $primary-white;
  }

  .suffix svg {
    fill: $primary-red;
  }

  .input {
    background-color: $primary-red-light;
  }
}

.input-mock {
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: $primary-white;

  border: {
    width: 1px;
    color: transparent;
    radius: 2px;
    style: solid;
    color: $primary-grey-300;
  }
}

.input {
  composes: base;
  &:focus {
    box-shadow: none;
  }

  order: 1;
  margin: 1px;

  &:-webkit-autofill ~ .input-mock {
    background-color: $primary-powder-blue-light;
  }
}

.textfield--medium .affix:not(.plain) {
  padding: $input-medium-padding;
}

.textfield--medium .suffix:not(.plain) {
  padding-left: 0;
}

.disabled {
  &,
  .input-mock {
    background-color: $primary-grey-100;
  }
}

.focused {
  background-color: $primary-white;
  caret-color: $primary-ph-blue; // Edge doesn't support it @fixme?

  .suffix svg {
    fill: $primary-ph-blue;
  }

  .input-mock {
    background-color: $primary-white;
    border-color: $primary-ph-blue;
    box-shadow: 0 0 4px $primary-ph-blue;
  }
}

.focused.textfield--is-typing .input-mock {
  box-shadow: 0 2px 2px 0 rgba($primary-rich-blue, 0.2);
}

.textfield--with-pulsating-glow .input {
  @include animation(pulsating-glow, 0.8, ease-out, infinite, alternate);
}
