@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  width: 100%;
  height: 100%;
}

.content {
  width: 800px;
  max-width: 100%;
  min-height: calc(100vh - 72px);
  margin: 0 auto;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  composes: (header--64-500);

  margin: 0 0 16px;
}

.description {
  composes: (body--24-400);

  margin: 0;
  color: color.$primary-grey-600;
}

.button {
  margin-top: 24px;
}
