@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.wrapper {
  height: 100%;
  position: relative;
  display: flex;
}

.wrapper.open {
  min-width: 492px;
  background-color: color.$primary-white;
  border-right: 1px solid color.$primary-grey-300;
}

.wrapper.open.no-sidebar {
  min-width: 396px;
}

.tab,
.sidebar {
  animation: fadeIn 0.75s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.close {
  width: 16px;
  height: 80px;
  position: absolute;
  padding: 0;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  background-color: color.$primary-white;
  border: 1px solid color.$primary-grey-300;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  z-index: 1;
  transition: variables.$hover-transition;
}

.close svg {
  width: 8px;
  height: 8px;
}

.close:hover {
  background-color: color.$primary-grey-100;
}

.close:hover svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}
