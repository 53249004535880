/**
 * Font families for Editor
 *
 * If you modify this file, please NOTIFY No-touch & DTP team.
 */

/* Cardo */
@font-face {
  font-family: "TimesNewRoman";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/TimesNewRoman/Times-New-Roman-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TimesNewRoman";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/TimesNewRoman/Times-New-Roman-Bold-Italic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: Italic;
}

@font-face {
  font-family: "TimesNewRoman";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/TimesNewRoman/Times-New-Roman-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "TimesNewRoman";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/TimesNewRoman/Times-New-Roman-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Cardo */

@font-face {
  font-family: "Cardo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Cardo/Cardo-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cardo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Cardo/Cardo-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cardo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Cardo/Cardo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Cairo */

@font-face {
  font-family: "Cairo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Cairo/Cairo-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cairo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Cairo/Cairo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* IBMPlexSerif */

@font-face {
  font-family: "IBMPlexSerif";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/IBMPlexSerif/IBMPlexSerif-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexSerif";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/IBMPlexSerif/IBMPlexSerif-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexSerif";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/IBMPlexSerif/IBMPlexSerif-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "IBMPlexSerif";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/IBMPlexSerif/IBMPlexSerif-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* IndieFlower */

@font-face {
  font-family: "IndieFlower";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/IndieFlower/IndieFlower-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Lato */

@font-face {
  font-family: "Lato";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lato/Lato-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lato/Lato-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lato/Lato-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lato/Lato-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Montserrat */

@font-face {
  font-family: "Montserrat";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Montserrat/Montserrat-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Montserrat/Montserrat-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Montserrat/Montserrat-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Muli */

@font-face {
  font-family: "Muli";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Muli/Muli-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Roboto */

@font-face {
  font-family: "Roboto";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Roboto/Roboto-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Roboto/Roboto-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Roboto/Roboto-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Varela */
@font-face {
  font-family: "Varela";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Varela/Varela-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Alegreya */
@font-face {
  font-family: "Alegreya";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Alegreya/Alegreya-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Alegreya/Alegreya-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Alegreya/Alegreya-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Alegreya/Alegreya-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Archivo */
@font-face {
  font-family: "Archivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Archivo/Archivo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Archivo/Archivo-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Archivo/Archivo-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Archivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Archivo/Archivo-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Oswald */
@font-face {
  font-family: "Oswald";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Oswald/Oswald-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Oswald/Oswald-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Poppins */
@font-face {
  font-family: "Poppins";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Poppins/Poppins-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Poppins/Poppins-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Poppins/Poppins-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Poppins/Poppins-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Vollkorn */
@font-face {
  font-family: "Vollkorn";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Vollkorn/Vollkorn-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Vollkorn";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Vollkorn/Vollkorn-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Vollkorn";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Vollkorn/Vollkorn-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Vollkorn";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Vollkorn/Vollkorn-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* AbrilFatface */

@font-face {
  font-family: "AbrilFatface";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/AbrilFatface/AbrilFatface-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Lora */
@font-face {
  font-family: "Lora";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lora/Lora-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lora/Lora-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lora/Lora-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lora/Lora-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* BioRhyme */
@font-face {
  font-family: "BioRhyme";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BioRhyme/BioRhyme-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BioRhyme";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BioRhyme/BioRhyme-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* CrimsonText */
@font-face {
  font-family: "CrimsonText";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CrimsonText/CrimsonText-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CrimsonText";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CrimsonText/CrimsonText-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "CrimsonText";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CrimsonText/CrimsonText-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "CrimsonText";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CrimsonText/CrimsonText-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* PlayfairDisplay */
@font-face {
  font-family: "PlayfairDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PlayfairDisplay/PlayfairDisplay-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Pacifico */
@font-face {
  font-family: "Pacifico";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Pacifico/Pacifico-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* JustAnotherHand */
@font-face {
  font-family: "JustAnotherHand";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/JustAnotherHand/JustAnotherHand-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Aleo */
@font-face {
  font-family: "Aleo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Aleo/Aleo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Aleo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Aleo/Aleo-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Aleo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Aleo/Aleo-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Aleo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Aleo/Aleo-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* AmaticSC */
@font-face {
  font-family: "AmaticSC";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/AmaticSC/AmaticSC-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AmaticSC";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/AmaticSC/AmaticSC-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* ArchivoBlack */
@font-face {
  font-family: "ArchivoBlack";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ArchivoBlack/ArchivoBlack-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bahiana */
@font-face {
  font-family: "Bahiana";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bahiana/Bahiana-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* BerkshireSwash */
@font-face {
  font-family: "BerkshireSwash";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BerkshireSwash/BerkshireSwash-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/**
 * @deprecated - BigShouldersInlineDisplay
 *
 * [07/06/2021] we're deprecating this font. It is no longer available for user to choose, but we should load it to support old designs.
 * Consider removing this @font-face in the future.
 * https://app.clubhouse.io/packhelp/story/24279/high-remove-invalid-fonts
 */
@font-face {
  font-family: "BigShouldersInlineDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BigShouldersInlineDisplay/BigShouldersInlineDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BigShouldersInlineDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BigShouldersInlineDisplay/BigShouldersInlineDisplay-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* BigShouldersDisplay */
@font-face {
  font-family: "BigShouldersDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BigShouldersDisplay/BigShouldersDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BigShouldersDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BigShouldersDisplay/BigShouldersDisplay-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Bitter */
@font-face {
  font-family: "Bitter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bitter/Bitter-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Bitter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bitter/Bitter-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Bitter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bitter/Bitter-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Bitter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bitter/Bitter-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* CedarvilleCursive */
@font-face {
  font-family: "CedarvilleCursive";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CedarvilleCursive/CedarvilleCursive-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* ChakraPetch */
@font-face {
  font-family: "ChakraPetch";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ChakraPetch/ChakraPetch-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ChakraPetch/ChakraPetch-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ChakraPetch/ChakraPetch-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ChakraPetch/ChakraPetch-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* DMSerifDisplay */
@font-face {
  font-family: "DMSerifDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/DMSerifDisplay/DMSerifDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DMSerifDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/DMSerifDisplay/DMSerifDisplay-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Itim */
@font-face {
  font-family: "Itim";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Itim/Itim-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* JetBrainsMono */
@font-face {
  font-family: "JetBrainsMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "JetBrainsMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/JetBrainsMono/JetBrainsMono-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "JetBrainsMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/JetBrainsMono/JetBrainsMono-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "JetBrainsMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/JetBrainsMono/JetBrainsMono-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* KronaOne */
@font-face {
  font-family: "KronaOne";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/KronaOne/KronaOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* KumarOneOutline */
@font-face {
  font-family: "KumarOneOutline";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/KumarOneOutline/KumarOneOutline-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* MajorMonoDisplay */
@font-face {
  font-family: "MajorMonoDisplay";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/MajorMonoDisplay/MajorMonoDisplay-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Mali */
@font-face {
  font-family: "Mali";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Mali/Mali-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Mali";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Mali/Mali-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Mali";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Mali/Mali-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Mali";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Mali/Mali-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Manrope */
@font-face {
  font-family: "Manrope";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Manrope/Manrope-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Manrope/Manrope-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* PetitFormalScript */
@font-face {
  font-family: "PetitFormalScript";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PetitFormalScript/PetitFormalScript-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Plaster */
@font-face {
  font-family: "Plaster";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Plaster/Plaster-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* PoiretOne */
@font-face {
  font-family: "PoiretOne";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PoiretOne/PoiretOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Prompt */
@font-face {
  font-family: "Prompt";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Prompt/Prompt-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Prompt/Prompt-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Prompt";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Prompt/Prompt-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Prompt";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Prompt/Prompt-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Quicksand */
@font-face {
  font-family: "Quicksand";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Quicksand/Quicksand-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Quicksand/Quicksand-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* ReenieBeanie */
@font-face {
  font-family: "ReenieBeanie";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/ReenieBeanie/ReenieBeanie-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* RobotoMono */
@font-face {
  font-family: "RobotoMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoMono/RobotoMono-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoMono/RobotoMono-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "RobotoMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoMono/RobotoMono-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "RobotoMono";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoMono/RobotoMono-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* RobotoSlab */
@font-face {
  font-family: "RobotoSlab";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoSlab/RobotoSlab-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "RobotoSlab";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RobotoSlab/RobotoSlab-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Rubik */
@font-face {
  font-family: "Rubik";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rubik/Rubik-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rubik/Rubik-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rubik/Rubik-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Rubik";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rubik/Rubik-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* RubikMonoOne */
@font-face {
  font-family: "RubikMonoOne";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RubikMonoOne/RubikMonoOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/**
 * @deprecated - Stalemate
 *
 * [07/06/2021] we're deprecating this font. It is no longer available for user to choose, but we should load it to support old designs.
 * Consider removing this @font-face in the future.
 * https://app.clubhouse.io/packhelp/story/24279/high-remove-invalid-fonts
 */
@font-face {
  font-family: "Stalemate";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Stalemate/Stalemate-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* TenorSans */
@font-face {
  font-family: "TenorSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/TenorSans/TenorSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Yellowtail */
@font-face {
  font-family: "Yellowtail";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Yellowtail/Yellowtail-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* YesevaOne */
@font-face {
  font-family: "YesevaOne";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/YesevaOne/YesevaOne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* BebasNeue */
@font-face {
  font-family: "BebasNeue";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/BebasNeue/BebasNeue-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Chivo */
@font-face {
  font-family: "Chivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Chivo/Chivo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Chivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Chivo/Chivo-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Chivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Chivo/Chivo-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Chivo";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Chivo/Chivo-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* CormorantGaramond */
@font-face {
  font-family: "CormorantGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CormorantGaramond/CormorantGaramond-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CormorantGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CormorantGaramond/CormorantGaramond-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "CormorantGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CormorantGaramond/CormorantGaramond-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "CormorantGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CormorantGaramond/CormorantGaramond-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Domine */
@font-face {
  font-family: "Domine";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Domine/Domine-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Domine";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Domine/Domine-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* EBGaramond */
@font-face {
  font-family: "EBGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/EBGaramond/EBGaramond-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EBGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/EBGaramond/EBGaramond-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "EBGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/EBGaramond/EBGaramond-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "EBGaramond";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/EBGaramond/EBGaramond-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* GrandHotel */
@font-face {
  font-family: "GrandHotel";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/GrandHotel/GrandHotel-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* GreatVibes */
@font-face {
  font-family: "GreatVibes";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/GreatVibes/GreatVibes-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Judson */
@font-face {
  font-family: "Judson";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Judson/Judson-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Judson";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Judson/Judson-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Judson";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Judson/Judson-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

/**
 * @deprecated - Martel
 *
 * [07/06/2021] we're deprecating this font. It is no longer available for user to choose, but we should load it to support old designs.
 * Consider removing this @font-face in the future.
 * https://app.clubhouse.io/packhelp/story/24279/high-remove-invalid-fonts
 */
@font-face {
  font-family: "Martel";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Martel/Martel-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Martel";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Martel/Martel-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Meddon */
@font-face {
  font-family: "Meddon";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Meddon/Meddon-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* OpenSans */
@font-face {
  font-family: "OpenSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/OpenSans/OpenSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/OpenSans/OpenSans-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/OpenSans/OpenSans-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "OpenSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/OpenSans/OpenSans-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Parisienne */
@font-face {
  font-family: "Parisienne";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Parisienne/Parisienne-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* PatrickHandSC */
@font-face {
  font-family: "PatrickHandSC";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/PatrickHandSC/PatrickHandSC-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Raleway */
@font-face {
  font-family: "Raleway";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Raleway/Raleway-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Raleway/Raleway-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Raleway/Raleway-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Raleway";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Raleway/Raleway-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/**
 * @deprecated - Rufina
 *
 * [07/06/2021] we're deprecating this font. It is no longer available for user to choose, but we should load it to support old designs.
 * Consider removing this @font-face in the future.
 * https://app.clubhouse.io/packhelp/story/24279/high-remove-invalid-fonts
 */
@font-face {
  font-family: "Rufina";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rufina/Rufina-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rufina";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Rufina/Rufina-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Sacramento */
@font-face {
  font-family: "Sacramento";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Sacramento/Sacramento-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Sen */
@font-face {
  font-family: "Sen";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Sen/Sen-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sen";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Sen/Sen-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* WorkSans */
@font-face {
  font-family: "WorkSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/WorkSans/WorkSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/WorkSans/WorkSans-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/WorkSans/WorkSans-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/WorkSans/WorkSans-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* BirthstoneBounce */

@font-face {
  font-family: "BirthstoneBounce";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Birthstone_Bounce/BirthstoneBounce-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "BirthstoneBounce";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Birthstone_Bounce/BirthstoneBounce-Medium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: bold;
}

/* Bungee */

@font-face {
  font-family: "Bungee";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Bungee/Bungee-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* Comforter */

@font-face {
  font-family: "Comforter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Comforter/Comforter-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* OoohBaby */

@font-face {
  font-family: "OoohBaby";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Oooh_Baby/OoohBaby-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* Ultra */

@font-face {
  font-family: "Ultra";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Ultra/Ultra-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* Barriecito */
@font-face {
  font-family: "Barriecito";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Barriecito/Barriecito-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* Ambidexter */
@font-face {
  font-family: "Ambidexter";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Ambidexter/Ambidexter-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

/* CutItOut */
@font-face {
  font-family: "CutItOut";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/CutItOut/CutItOut-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

/* GranaPadano */
@font-face {
  font-family: "GranaPadano";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/GranaPadano/GranaPadano-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* DayCare */
@font-face {
  font-family: "Daycare";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/DayCare/DayCare-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

/* Kawoszeh */
@font-face {
  font-family: "Kawoszeh";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Kawoszeh/Kawoszeh-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* Lombard */
@font-face {
  font-family: "Lombard";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/Lombard/Lombard-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* LemonTuesday */
@font-face {
  font-family: "LemonTuesday";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/LemonTuesday/LemonTuesday-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

/* RibesBlack */
@font-face {
  font-family: "RibesBlack";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/RibesBlack/RibesBlack-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

/* SpaceGrotesk */
@font-face {
  font-family: "SpaceGrotesk";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("https://editor.s3.eu-central-1.amazonaws.com/fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: bold;
}
