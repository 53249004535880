/** @prettier */
@use "../../../../theme/color";

$arrow-size: 14px;
$button-width: 47px;

.button {
  background: none;
  border: none;
  width: $button-width;
  cursor: pointer;

  &:focus {
    outline: none;
    .icon {
      fill: color.$primary-dark-blue;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.icon {
  width: $arrow-size;
  height: $arrow-size;
  font-size: $arrow-size;
  transition: 250ms ease-in-out;
  fill: currentColor;

  &--active {
    transform: rotate(180deg);
    color: color.$primary-dark-blue;
  }

  &--disabled {
    color: color.$primary-grey-500;
  }
}
