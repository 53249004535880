@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";
@use "../../../../_settings/variables" as variables;

.font-family {
  width: 140px;
}

.font-size {
  width: 82px;
}

.icon-list {
  margin: 0 -2px;
}

.icon-list button {
  margin: 0 2px;
}

.button {
  composes: (body--15-350);

  height: 36px;
  padding: 8px;
  margin: 0 -8px;
  border-radius: variables.$border-radius;
  border: none;
  background: none;
  cursor: pointer;
  line-height: normal;
  transition: variables.$hover-transition;
}

.button:hover {
  background-color: color.$primary-grey-100;
  color: var(--color-primary, color.$primary-ph-blue);
}
