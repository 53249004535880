@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.wrapper {
  position: absolute;
  pointer-events: all;
  background-color: color.$primary-white;
  border-radius: variables.$border-radius;
  border: 1px solid color.$primary-grey-300;
  box-shadow: variables.$box-shadow;
}

.wrapper.top {
  transform: translate(-50%, calc(-100% - 4px));
}

.wrapper.bottom {
  transform: translate(-50%, 4px);
}

.wrapper > nav button {
  margin: 0;
}

.wrapper svg use {
  fill: color.$primary-rich-blue;
}
