.wrapper {
  width: auto;
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  &--cursor-default {
    cursor: default;
  }
}
