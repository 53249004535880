@use "../../_settings/variables" as variables;

.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get(variables.$z-index, globalDropzone);
  pointer-events: all;
}

.wrapper > * {
  width: 100%;
  height: 100%;

  background-color: rgba(248, 248, 248, 0.8);
}
