@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  height: 40px;
  padding: 0 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: variables.$border-radius;
}

.wrapper.active,
.wrapper.highlighted,
.wrapper:hover {
  background: color.$primary-grey-100;
}

.wrapper svg use {
  fill: color.$primary-grey-200;
}
