@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.sidebar {
  width: 96px;
  height: 100%;
  display: grid;
  gap: 2px;
  place-content: start;
  grid-template-columns: 1fr;
  padding: 12px 8px;
  box-sizing: border-box;
  background-color: color.$primary-white;
  border-right: 1px solid color.$primary-grey-300;
  flex-shrink: 0;
}

.item {
  composes: (body--11-350);

  width: 100%;
  min-height: 72px;
  padding: 14px 4px;
  display: grid;
  gap: 8px;
  place-content: center;
  place-items: center;
  word-break: break-word;
  border: 0;
  border-radius: variables.$border-radius;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.item:hover {
  color: var(--color-primary, color.$primary-ph-blue);
}

.item:hover,
.item.active {
  background-color: color.$primary-grey-200;
}

.icon {
  width: variables.$icon-size;
  height: variables.$icon-size;
  position: relative;
}

.icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.icon svg use {
  fill: color.$primary-grey-600;
}

// start: for WL purpose we need div container
.icon div:first-child svg {
  visibility: visible;
}
.icon div:last-child:not(:first-child) svg {
  visibility: hidden;
}
.item:hover .icon div:last-child svg,
.item.active .icon div:last-child svg {
  visibility: visible;
}
.item:hover .icon div:first-child svg,
.item.active .icon div:first-child svg {
  visibility: hidden;
}
// finish: for WL purpose we need div container

.icon svg:first-child {
  visibility: visible;
}

.icon svg:last-child:not(:first-child) {
  visibility: hidden;
}

.item:hover .icon svg use,
.item.active .icon svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.item:hover .icon svg:first-child,
.item.active .icon svg:first-child {
  visibility: hidden;
}

.item:hover .icon svg:last-child,
.item.active .icon svg:last-child {
  visibility: visible;
}
