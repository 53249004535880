@use "~dsl/src/theme/_color.scss" as color;
@use "../../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.wrapper.normal {
  width: 396px;
}

.wrapper.wide {
  width: 712px;
}

.body {
  width: 100%;
  height: 100%;
  position: relative;
}

.tab {
  width: 100%;
  height: 100%;
  padding: 40px 24px 40px 40px;
  display: grid;
  gap: 32px;
  place-content: start;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  background-color: color.$primary-white;
  overflow: auto;
  scrollbar-gutter: stable;
}

.tab::-webkit-scrollbar {
  width: 16px;
}

.tab::-webkit-scrollbar-track {
  background: color.$primary-grey-100;
}

.tab::-webkit-scrollbar-thumb {
  background-color: color.$primary-grey-400;
  border-radius: 8px;
  border: 4px solid color.$primary-grey-100;
}

.title {
  composes: (header--32-400);

  margin: 0;
}

.back {
  composes: (body-uc--13-400);

  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: color.$primary-grey-600;
  line-height: normal;
  transition: variables.$hover-transition;
}

.back:hover {
  color: color.$primary-rich-blue;
}

.back svg {
  margin-right: 12px;
  padding: 10px;
  border-radius: 50%;
  background-color: color.$primary-grey-200;
  transition: variables.$hover-transition;
}

.back:hover svg {
  background-color: color.$primary-grey-300;
}
