@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  width: 100%;
  min-width: 140px;
  position: relative;
  margin-top: -6px;
  flex-grow: 1;
}

.slider {
  position: relative;
  top: 1px;
  z-index: 1;
}

.steps-list {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: -0.15rem 0 0;
  padding: 0;

  list-style: none;
}

.steps-list-item {
  flex: 1;

  &:not(:last-child) {
    margin-right: 1px;
  }
}

.step {
  height: 8px;

  border-radius: 4px;
}

@for $i from 1 through 10 {
  .steps-list-item:nth-child(#{$i}) .step {
    background-color: lighten(color.$primary-rich-blue, $i * 9%);
  }
}

.steps-list-item:last-child .step {
  border: 1px solid color.$primary-grey-400;
  background-color: color.$primary-white;
}

.color {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 100%;
  padding: 3px;

  cursor: pointer;

  border: 1px solid color.$primary-grey-400;
  background-color: transparent;

  &:focus,
  &:active {
    outline: none;
  }
}

.color span {
  text-transform: uppercase;
}

.color span + span {
  margin-left: 4px;
}
