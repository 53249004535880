/** @prettier */
@use "../../../../theme/spacing";
@use "../../../../theme/color";
@use "../../../../theme/transition";

@keyframes pulsating-glow {
  0% {
    border-color: color.$primary-ph-blue;
    box-shadow: 0 0 4px color.$primary-ph-blue;
  }
  100% {
    border-color: color.$primary-grey-300;
    box-shadow: 0 0 20px color.$primary-white;
  }
}

.wrapper {
  display: block;
  margin-bottom: spacing.$spacing-s2;
}

.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: spacing.$spacing-s7;
  border: color.$primary-grey-300 1px solid;
  border-radius: 4px;
  background-color: color.$primary-white;
  transition: 250ms ease-in-out;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    cursor: pointer;
    border-color: color.$primary-dark-blue;
  }

  &--with-glow {
    @include transition.animation(
      pulsating-glow,
      0.8,
      ease-out,
      infinite,
      alternate
    );
  }

  &--active {
    border-color: color.$primary-dark-blue;
    box-shadow: 0 0 4px color.$primary-dark-blue;
  }

  &--disabled {
    background-color: color.$primary-grey-100;
    color: color.$primary-grey-500;
    cursor: not-allowed;
  }
}

.inner {
  display: flex;
  flex: 1;
  padding: 0 spacing.$spacing-s3;
}

.value-wrapper {
  display: flex;
  flex: 1;
  padding-left: spacing.$spacing-s3;
}
