@import "../../_theme.scss";
$size-border-radius: 8px;
$size-border-radius-4: 4px;
$size-border-radius-16: 16px;
$size-box-shadow: 10px;

.base {
  display: block;

  border: 1px solid $primary-grey-300;
  background-color: $primary-white;
}

.base--hidden-overflow {
  overflow: hidden;
}

.base--no-top {
  border-top: 0;
}

.base--no-border {
  border: 0;
}

.fluid {
  width: 100%;
}

.base.base--full-height {
  height: 100%;
}

.base--medium-height {
  height: calc(100% - #{$spacing-s6});
}

.base--small-height {
  height: 256px;
}

.base--auto-height {
  height: auto;
  max-height: 100%;
}

.square {
  border-radius: 0;
}

.base--shadow {
  box-shadow: 0 $size-box-shadow $size-box-shadow 0
    rgba($primary-rich-blue, 0.1);
}

.base--shadow-thick {
  box-shadow: 0 5px 5px 0 rgba($primary-rich-blue, 0.2);
}

.round4 {
  border-radius: $size-border-radius-4;
}

.round {
  border-radius: $size-border-radius;
}

.rounded-top {
  border-radius: $spacing-s3 $spacing-s3 0 0;
}

.round16 {
  box-sizing: border-box;
  border-radius: $size-border-radius-16;
}

.base--no-top.round {
  border-radius: 0 0 $size-border-radius $size-border-radius;
}

.overlay {
  border: 0;
  background-color: rgba($primary-grey-400, 0.95);
}

.clear {
  display: inline-block;

  border: 0;
  background-color: transparent;
}

.link {
  text-decoration: none;
}

@include breakpoint-mobile {
  .mobile-round {
    border-radius: $size-border-radius;
  }
  .mobile-round16 {
    border-radius: $size-border-radius-16;
  }
  .mobile-square {
    border-radius: 0;
  }
  .mobile-clear {
    display: inline-block;

    border: 0;
    background-color: transparent;
  }

  .mobile-overlay {
    border: 0;
    background-color: rgba($primary-grey-400, 0.8);
  }

  .mobile-rounded-top {
    border-radius: $spacing-s3 $spacing-s3 0 0;
  }

  .mobile-border-top {
    border: 0;
    border-top: 1px solid $primary-grey-300;
  }

  .fluid--on-mobile {
    width: 100%;
  }
}
