@use "~dsl/src/theme/_spacing.scss" as spacing;
@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

$pickerToggleSize: 32px;
$wrapperColorWidth: 368px;

.wrapper {
  display: grid;
  gap: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  composes: (header--17-400);

  margin: 0;
}
