@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  gap: 8px;
  place-items: center;
  cursor: pointer;
  border: none;
  background: none;
}

.wrapper:hover svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.caption {
  composes: (header--13-500);

  background-color: var(--color-primary, color.$primary-ph-blue);
  color: color.$primary-white;
  padding: 4px;
  border-radius: variables.$border-radius;
  box-shadow: variables.$box-shadow;
}

.wrapper:hover .caption {
  background-color: var(--color-primary-dark, color.$primary-dark-blue);
}

.icon {
  border-radius: 50%;
  box-shadow: variables.$box-shadow;
}

.icon svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.wrapper:hover svg use {
  fill: var(--color-primary-dark, color.$primary-dark-blue);
}
