@import "../../_theme.scss";

.wrapper {
  width: 100%;
  flex-grow: 1;

  .range {
    min-height: 30px;

    &.range-small {
      min-height: auto;
    }

    &.range-disabled {
      .dot {
        cursor: default;
        pointer-events: none;

        background-color: $primary-grey-400;

        &:hover,
        &:active {
          width: 24px;
          height: 24px;

          border: none;
        }
      }

      .track {
        background-color: $primary-grey-400;
      }

      .track-background {
        height: 8px;

        background-color: $primary-grey-400;
      }

      .label {
        display: none;
      }
    }

    .track {
      height: 8px;

      transition: none;

      background: $primary-ph-blue;
    }

    .track--hidden {
      background-color: transparent;
    }

    .track-background {
      height: 8px;

      background-color: $primary-grey-400;

      .container {
        transition: none;
      }
    }

    .track-background--transparent {
      background-color: transparent;
    }

    .max-label {
      right: 20px;

      font-size: $font-size-13;

      color: $primary-rich-blue;
    }

    .min-label {
      left: 7px;

      font-size: $font-size-13;

      color: $primary-rich-blue;
    }

    .basic-label-disabled {
      display: none;
    }

    .dot {
      width: 12px;
      height: 12px;
      margin-top: -10px;

      border: none;
      background: $primary-white;
      box-shadow: 0 0 5px rgba($primary-rich-blue, 0.4);

      &:focus {
        width: 23px;
        height: 23px;
        margin-top: -15px;
        margin-left: -12px;

        border: 1px solid $primary-ph-blue;
        box-shadow: 0 0 4px $primary-ph-blue;
      }

      &:hover,
      &:active {
        width: 23px;
        height: 23px;
        margin-top: -15px;
        margin-left: -12px;

        border: 1px solid $primary-ph-blue;
        box-shadow: 0 2px 2px rgba($primary-rich-blue, 0.2);
      }

      &:active {
        transform: none;
      }
    }

    .dot--big {
      width: 23px;
      height: 23px;
      margin-top: -16px;
      margin-left: -12px;
    }

    .label {
      .label-block {
        bottom: 12px;
        left: 3px;

        padding: 5px;

        font-family: $font-family-default;
        font-size: $font-size-13;

        color: $primary-white;
        border-radius: 2px;
        background-color: $primary-ph-blue;
      }
    }

    .advanced-label-disabled {
      display: none;
    }
  }
}

@include breakpoint-mobile {
  .wrapper {
    .range {
      .dot {
        width: 32px;
        height: 32px;
        margin-top: -20px;

        &:focus {
          width: 31px;
          height: 31px;
        }

        &:hover,
        &:active {
          width: 31px;
          height: 31px;
        }
      }

      .label {
        .label-block {
          bottom: 14px;
        }
      }
    }
  }
}
