@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.icon svg use {
  fill: color.$primary-grey-400;
}

.title {
  composes: (header--32-400);

  margin: 36px 0 8px;
}

.description {
  composes: (body--17-350);

  margin: 0;
  color: color.$primary-grey-600;
}
