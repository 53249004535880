@import "../../_theme.scss";

.z-color-picker {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 300px;
  flex-direction: column;

  &--hue-and-saturation {
    display: flex;
    flex-direction: row;
    height: 143px;
    margin-bottom: 13px;

    .hue-vertical {
      background: #f9f9f9;
    }
  }

  &--hue {
    background: #f9f9f9;
  }

  &--colors_inputs {
    display: flex;

    input {
      height: 40px;
      width: 40px;
      line-height: 30px;
      border: 1px solid #cacaca;
      order: 2;
      padding: 0 12px;
      margin-left: 4px;
      font-size: 14px;

      box-sizing: border-box;
    }

    span {
      order: 1;
      color: #2b2b2b;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.4px;
      margin-left: 4px;
    }
    &--rgb,
    &--hex {
      > div {
        > div {
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 7px;
            line-height: 12px;
          }
        }
      }
    }

    &--rgb {
      display: flex;

      > div {
        width: calc(100% / 3);

        > div {
          margin-right: 4px;

          span {
            min-width: 18px;
          }

          input {
            width: 100%;
            max-width: 60px;
          }
        }
      }
    }

    &--hex {
      > div {
        > div {
          flex-direction: column;

          input {
            width: 138px;
            margin: 0;
          }

          span {
            margin-left: 0;
          }
        }
      }
    }
  }

  &--hueContainer {
    position: relative;
    flex: 0 0 7px;
    margin: 0 20px 0 10px;
  }

  &--saturationContainer {
    position: relative;
    flex: 0 0 calc(100% - 37px);
  }
}

.z-hue-pointer {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #fff;
  display: block;
  box-shadow: 0 0px 5px 2px rgba(47, 47, 47, 0.2);
  margin-left: -4px;
}
