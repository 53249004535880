@use "~dsl/src/theme/_color.scss" as color;

.button {
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

.button svg use {
  fill: color.$primary-rich-blue;
}

.button:hover svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}
