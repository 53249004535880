@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

$border-radius: 99px;

.item {
  composes: (body--15-350);

  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  place-items: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  background-color: color.$primary-grey-200;
  cursor: pointer;
  white-space: nowrap;
  line-height: normal;
  transition: variables.$hover-transition;
}

.item.icon-only {
  padding: 8px 11px;
}

.item.active,
.item.highlighted {
  background-color: color.$primary-white;
  border-color: color.$primary-grey-300;
}

.item.highlighted + .item.highlighted {
  margin-left: 4px;
}

.item.active {
  color: color.$primary-rich-blue;
  cursor: default;
}

.item.active.highlighted {
  border: 1px solid var(--color-primary, color.$primary-ph-blue);
}

.item:not(.active):disabled {
  color: color.$primary-grey-500;
  cursor: default;
}

.item:not(.active):disabled svg use {
  fill: color.$primary-grey-500;
}

.item:not(.active):not(:disabled):hover {
  background-color: color.$primary-grey-100;
  border-color: color.$primary-grey-300;
  color: var(--color-primary, color.$primary-ph-blue);
}
