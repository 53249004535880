@import "./_breakpoint-variables.scss";

@mixin breakpoint-xs() {
  @media (max-width: #{$breakpoint-mobile-lg}px) {
    @content;
  }
}

@mixin breakpoint-sm() {
  @media (max-width: #{$breakpoint-tablet-md}px) {
    @content;
  }
}
@mixin breakpoint-not-sm() {
  @media (min-width: #{$breakpoint-tablet-md + 1}px) {
    @content;
  }
}

@mixin breakpoint-not-xs() {
  @media (min-width: #{$breakpoint-mobile-lg + 1}px) {
    @content;
  }
}

@mixin breakpoint-mobile() {
  @include breakpoint-sm() {
    @content;
  }
}

@mixin breakpoint-not-mobile() {
  @include breakpoint-not-sm() {
    @content;
  }
}

@mixin breakpoint-md() {
  @media (max-width: #{$breakpoint-desktop-md}px) {
    @content;
  }
}

@mixin breakpoint-lg() {
  @media (min-width: #{$breakpoint-desktop-md + 1}px) {
    @content;
  }
}

@mixin breakpoint-tablet-only() {
  @media (max-width: #{$breakpoint-tablet-md}px) and (min-width: #{$breakpoint-mobile-lg + 1}px) {
    @content;
  }
}

@mixin breakpoint-tablet-to-desktop() {
  @media (max-width: #{$breakpoint-desktop-md}px) and (min-width: #{$breakpoint-tablet-md + 1}px) {
    @content;
  }
}

@mixin navigation-breakpoint-mobile() {
  @include breakpoint-xs() {
    @content;
  }
}

@mixin navigation-breakpoint-desktop() {
  @media (min-width: #{$breakpoint-mobile-lg + 1}px) {
    @content;
  }
}

@mixin breakpoint-not-desktop() {
  @media (max-width: #{$breakpoint-tablet-lg + 1}px) {
    @content;
  }
}

@mixin breakpoint-mobile-sm() {
  @media (max-width: #{$breakpoint-mobile-sm}px) {
    @content;
  }
}

@mixin breakpoint-mobile-md() {
  @media (max-width: #{$breakpoint-mobile-md}px) {
    @content;
  }
}
