@use "~dsl/src/theme/_color.scss" as color;
@use "../../../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  box-sizing: border-box;
  border: 1px solid color.$primary-grey-300;
  border-radius: variables.$border-radius;
}

.wrapper:hover,
.wrapper.active {
  border-color: var(--color-primary, color.$primary-ph-blue);
}

.button {
  composes: (body--15-350);

  width: 100%;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
}

.button > svg {
  margin-right: 8px;
}

.note {
  margin: 0 0 0 auto;
  color: color.$primary-grey-500;
}

.content {
  display: grid;
  gap: 8px;
  padding: 14px 16px;
}

.content input,
.content input + span {
  padding: 8px;
}

.error {
  composes: (body--13-350);

  color: color.$primary-red;
}

.error:not(:last-child) {
  margin-bottom: 8px;
}
