@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  composes: (body-uc--13-400);

  display: grid;
  gap: 20px;
  place-items: center;
  color: color.$primary-grey-600;
}

.loader {
  height: 48px;
  animation: loading 1.5s linear infinite;
}

.loader img {
  max-height: 100%;
}

@keyframes loading {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
