/** @prettier */
@use "../../../../theme/spacing";
@use "../../../../theme/color";

.search {
  padding: spacing.$spacing-s2;
  border-bottom: color.$primary-grey-400 1px solid;

  &--mobile {
    padding: spacing.$spacing-s3;
  }
}
