@use "src/ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button,
.link {
  width: calc(100% + 16px);
  margin: 0 -8px;
  display: flex;
  align-items: center;
  padding: 14px 8px;
  border-radius: variables.$border-radius;
  transition: variables.$hover-transition;
}

.button {
  composes: (body--17-350);

  border: 0;
  background: none;
  text-align: left;
  cursor: pointer;
  line-height: 1;
}

.button:disabled {
  width: auto;
  cursor: default;
  color: color.$primary-grey-500;
}

.button:not(:disabled):hover,
.link:hover {
  background-color: color.$primary-grey-100;
  color: var(--color-primary, color.$primary-ph-blue);
}

.link {
  composes: (body--17-350);

  width: 100%;
  display: flex;
  align-items: center;
  color: color.$primary-rich-blue;
  text-decoration: none;
  line-height: 1;
}

.icon {
  width: variables.$icon-size;
  height: variables.$icon-size;
  position: relative;
}

.icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.icon svg:first-child {
  visibility: visible;
}

.icon svg:last-child:not(:first-child) {
  visibility: hidden;
}

.button:not(:disabled):hover .icon svg:first-child,
.link:hover .icon svg:first-child {
  visibility: hidden;
}

.button:not(:disabled):hover .icon svg:last-child,
.link:hover .icon svg:last-child {
  visibility: visible;
}

.button .icon,
.link .icon {
  margin-right: 16px;
}

.button .icon svg use,
.link .icon svg use {
  fill: color.$primary-rich-blue;
}

.button:hover .icon svg use,
.link:hover .icon svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}

.button:disabled .icon svg use {
  fill: color.$primary-grey-500;
}

.arrow {
  margin: 0 0 0 auto;
}

.arrow svg use {
  fill: color.$primary-grey-500;
}

.button:hover .arrow svg use,
.link:hover .arrow svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}
