@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";
@use "../../../_settings/variables" as variables;

.wrapper {
  display: grid;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}

.title {
  composes: (header--17-400);

  margin: 0;
}

.link {
  composes: (body--15-350);

  margin: 0 0 0 auto;
  padding: 0;
  background: none;
  border: 0;
  color: color.$primary-grey-600;
  cursor: pointer;
  transition: variables.$hover-transition;
}

.link:hover {
  color: color.$primary-rich-blue;
}
