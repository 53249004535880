@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.link {
  composes: (header--17-400);

  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid color.$primary-grey-100;
  border-radius: variables.$border-radius;
  background-color: color.$primary-grey-100;
  text-decoration: none;
  color: color.$primary-rich-blue;
  transition: variables.$hover-transition;
}

.link:hover {
  border: 1px solid var(--color-primary, color.$primary-ph-blue);
}

.link svg {
  margin-right: 8px;
}
