@use "../../theme/_color.scss" as color;

$loader-size-small: 16px;
$loader-size-medium: 40px;
$loader-size-large: 120px;

@mixin size($size) {
  width: $size;
  height: $size;
}

.loader-vanilla {
  background-color: var(--color-primary, color.$primary-ph-blue);
  margin: 40px auto;
  animation: rotate 1.2s infinite ease-in-out;
  display: block;
}

.loader-vanilla.size {
  &-small {
    @include size($loader-size-small);
  }

  &-medium {
    @include size($loader-size-medium);
  }

  &-large {
    @include size($loader-size-large);
  }
}

.loader-vanilla.color {
  &-gray {
    background-color: color.$primary-grey-500;
  }
}

.loader-circular {
  border-radius: 50%;
  animation: spin 2s linear infinite;
  display: block;
}

.loader-circular.size {
  &-small {
    @include size($loader-size-small);

    border: 2px solid var(--color-primary, color.$primary-ph-blue);
    border-top-color: #f3f3f3;
    box-sizing: border-box;
    &.loader--light {
      border: 2px solid #f3f3f3;
      border-top-color: var(--color-primary, color.$primary-ph-blue);
    }
  }

  &-medium {
    @include size($loader-size-medium);

    border: 5px solid var(--color-primary, color.$primary-ph-blue);
    border-top: 5px solid #f3f3f3;
    &.loader--light {
      border: 5px solid #f3f3f3;
      border-top: 5px solid var(--color-primary, color.$primary-ph-blue);
    }
  }

  &-large {
    @include size($loader-size-large);

    border: 16px solid var(--color-primary, color.$primary-ph-blue);
    border-top-color: #f3f3f3;
    &.loader--light {
      border: 16px solid #f3f3f3;
      border-top-color: var(--color-primary, color.$primary-ph-blue);
    }
  }
}

.loader-circular.color {
  &-gray {
    border-color: color.$primary-grey-500;
    border-top-color: #f3f3f3;

    &.loader--light {
      border-color: #f3f3f3;
      border-top-color: color.$primary-grey-500;
    }
  }
}

@keyframes rotate {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
