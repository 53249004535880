@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";
@use "../../_settings/variables" as variables;

.wrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 24px;
  place-items: center;
  z-index: map-get(variables.$z-index, cameraControls);
}

.space {
  composes: (body--15-350);

  color: color.$primary-grey-600;
}
