@use "~dsl/src/theme/_color.scss" as color;
@use "src/ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get(variables.$z-index, modal);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$overlay-color;
  animation: fadeIn 0.35s ease-in-out;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}

.wrapper {
  width: 504px;
  position: relative;
  box-sizing: border-box;
  padding: 48px 24px 24px;
  border-radius: 16px;
  background-color: color.$primary-white;
  box-shadow: variables.$box-shadow;
}

.header,
.content {
  display: grid;
}

.wrapper.center .header,
.wrapper.center .content {
  place-items: center;
  text-align: center;
}

.wrapper.center .header {
  margin-bottom: 16px;
}

.wrapper.center .content {
  max-width: 320px;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
}

.header {
  margin-bottom: 32px;
}

.header img {
  width: 88px;
  height: 88px;
  margin: 24px 0 40px;
}

.title {
  composes: (header--32-400);

  margin: 0;
}

.content {
  composes: (body--15-350);

  display: grid;
  gap: 32px;
  color: color.$primary-grey-600;
}

.footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer :last-child {
  margin: 0 0 0 auto;
}
