@import "../../_theme.scss";

.wrapper {
  padding: $spacing-s1 $spacing-s2;

  display: inline-block;
  line-height: 120% !important;
  letter-spacing: 0.01em !important;

  color: $primary-white;
  border-radius: 2px;
  margin: 0 5px;
  /**
    in the end to hack astro css compiler
    https://github.com/vitejs/vite/issues/3924#issuecomment-1185919568
  */
  composes: body-uc--10-400 from "../../theme/typography.module.scss";
}

.wrapper--no-margin {
  margin: unset;
}

.wrapper--no-wrap {
  white-space: nowrap;
}

.wrapper--black {
  background-color: $primary-rich-blue;
}

.wrapper--green {
  background-color: $primary-green;
}

.wrapper--lightish-blue {
  background-color: $primary-ph-blue;
}

.wrapper--squash {
  background-color: $primary-yellow;
}

.wrapper--light-squash {
  color: $primary-rich-blue;
  background-color: $primary-yellow-light;
}

.wrapper--brown-grey {
  background-color: $primary-grey-500;
}

.wrapper--white-three {
  color: $primary-rich-blue;
  background-color: $primary-white;
}

.wrapper--white-three-with-entan-red {
  color: $primary-white;
  background-color: $primary-red;
}

.wrapper--white-three-with-border {
  color: $primary-rich-blue;
  background-color: $primary-white;
  border: 1px solid $primary-rich-blue;
}

.wrapper--white-four {
  color: $primary-rich-blue;
  background-color: $primary-grey-100;
}

.wrapper--bubblegum-pink {
  background-color: $primary-grey-500;
}

.wrapper--ultramarine {
  background-color: $primary-grey-500;
}

.wrapper--jagger {
  background-color: $primary-grey-500;
}

.wrapper--bright-cyan {
  background-color: $primary-ph-blue;
}

.wrapper--purple-blue {
  background-color: $primary-grey-500;
}

.wrapper--mariner {
  background-color: $primary-dark-blue;
}

.wrapper--french-pass {
  color: $primary-rich-blue;
  background-color: $primary-powder-blue-light;
}

.wrapper--pale-red {
  background-color: $primary-red;
}

.wrapper--very-light-pink {
  color: $primary-rich-blue;
  background-color: $primary-grey-400;
}

.wrapper--very-light-pink--gray {
  color: $primary-grey-500;
  background-color: $primary-grey-400;
}

.wrapper--very-light-pink-two {
  color: $primary-white;
  background-color: $primary-grey-400;
}

.wrapper--flush-mahogany {
  background-color: $primary-red;
}

.wrapper--christmas-green {
  background-color: $primary-green;
}

.wrapper--papaya-whip {
  color: $primary-yellow;
  background-color: $primary-yellow-light;
}

.wrapper--solitude {
  color: $primary-ph-blue;
  background-color: $primary-powder-blue-light;
}

.wrapper--orinoco {
  color: $primary-green;
  background-color: $primary-olive-light;
}

.wrapper--dove-gray {
  color: $primary-white;
  background-color: $primary-grey-500;
}

.wrapper--primary-plus-blue {
  color: $primary-white;
  background-color: $primary-ph-blue;
}

.wrapper--supporting-wild-sand {
  color: $primary-grey-500;
  background-color: $primary-grey-200;
}

.wrapper--default {
  color: $primary-grey-500;
  background-color: $primary-grey-100;
}

.wrapper--specialty {
  color: $primary-red;
  background-color: $primary-grey-100;
}

.wrapper--outline-black {
  padding: #{$spacing-s1 - 1};
  color: $primary-rich-blue;
  background-color: $primary-white;
  border: 1px solid $primary-rich-blue;
}

.wrapper--olive-light {
  color: $primary-green;
  background-color: $primary-olive-light;
}

%icon-base {
  display: inline-flex;
  align-items: center;

  &::before {
    content: "";
    display: inline-block;
    width: $spacing-s3;
    height: $spacing-s3;
    margin-right: $spacing-s1;
    text-align: center;
  }
}

.wrapper--icon-lightning {
  @extend %icon-base;

  &::before {
    // content: url("../../assets/icons/UI/Lightning.svg");
  }
}
