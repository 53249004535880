/* WARNING, ACHTUNG! AUTOGENERATED FILE */
/* Your changes to this file will perish */
@use "sass:math";

// Spacing
$spacing-s1: 4px;
$spacing-s2: 8px;
$spacing-s3: 16px;
$spacing-s4: 24px;
$spacing-s5: 32px;
$spacing-s6: 40px;
$spacing-s7: 48px;
$spacing-s8: 96px;
$spacing-s9: 160px;
