.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  place-items: start;
}

.list.cols-2 {
  grid-template-columns: 1fr 1fr;
}

.list.cols-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
