@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  composes: (body--15-350);

  position: relative;
  padding: 16px;
  border-radius: variables.$border-radius--l;
  display: flex;
  align-items: center;
}

.wrapper.with-close {
  padding-right: 32px;
}

.wrapper > svg {
  margin-right: 12px;
  flex-shrink: 0;
}

.wrapper.warning {
  background-color: color.$primary-yellow-light;
}

.wrapper.warning > svg use {
  fill: color.$primary-yellow;
}

.wrapper.error {
  background-color: color.$primary-red-light;
}

.wrapper.error > svg use {
  fill: color.$primary-red;
}

.wrapper.success {
  background-color: color.$primary-green;
  color: color.$primary-white;
}

.wrapper.success > svg use {
  fill: color.$primary-white;
}

.wrapper.info {
  background-color: color.$primary-powder-blue;
}

.wrapper a {
  color: color.$primary-rich-blue;
}

.small {
  composes: (body--11-350);

  padding: 8px;
}

.content nav {
  margin-top: 16px;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
}

.wrapper ul {
  margin: 0;
  padding-left: 28px;
}
