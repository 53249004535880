@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  width: 100%;
  height: 56px;
  padding: 0 20px;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  place-content: start;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  border-bottom: 1px solid color.$primary-grey-300;
  background-color: color.$primary-white;
}
