@use "~dsl/src/theme/_spacing" as s;
@use "~dsl/src/theme/_color" as c;

$zip-code-max-width: 120px;

.title {
  margin-bottom: s.$spacing-s4;
}

.grey {
  color: c.$primary-grey-600;
}

.wrapper {
  display: flex;
  gap: s.$spacing-s2;

  .country {
    flex-grow: 2;
  }

  .postcode {
    max-width: $zip-code-max-width;
  }
}

.action {
  padding-top: s.$spacing-s3;
}

.error {
  margin-bottom: s.$spacing-s4;
}

.error_list {
  margin-left: s.$spacing-s4;
}
