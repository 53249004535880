@import "../../_theme.scss";

.wrapper {
  position: relative;

  display: flex;
  overflow: hidden;

  padding: $spacing-s3;

  border-radius: $spacing-s1;
  background-color: $primary-grey-100;

  &--overflow-visible {
    overflow: visible;
  }
}

.wrapper--blue {
  background-color: $primary-powder-blue-light;
}

.wrapper--white {
  background-color: $primary-white;
}

.wrapper--grey {
  background-color: $primary-grey-100;
}

.wrapper--alabaster {
  background-color: $primary-grey-100;
}

.wrapper--red {
  background-color: $primary-red-light;
}

.wrapper--padding-thick {
  padding: $spacing-s4;
}

.wrapper--padding-slim {
  padding: $spacing-s2 $spacing-s3;
}

.wrapper--padding-none {
  padding: 0;
}

.wrapper--radius-8 {
  border-radius: $spacing-s2;
}

.decoration {
  width: 100%;

  &::after {
    position: absolute;

    display: block;

    content: "";
    user-select: none;

    background-repeat: no-repeat;
  }
}

.decoration--orange {
  &::after {
    width: 44px;
    height: 9px;

    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-image: url("../../assets/shapes/compositions/outlining-panel-orange.svg");
  }
}

.decoration--blue {
  &::after {
    width: 44px;
    height: 9px;

    right: 0;
    bottom: 0;

    background-image: url("../../assets/shapes/compositions/outlining-panel-blue.svg");
    background-position: center;
    background-size: cover;
  }
}

.decoration--blue-dark {
  &::after {
    width: 44px;
    height: 9px;

    right: 0;
    bottom: 0;

    background-image: url("../../assets/shapes/compositions/outlining-panel-blue-dark.svg");
    background-position: center;
    background-size: cover;
  }
}

.decoration--green {
  &::after {
    right: 0;
    bottom: 1px;

    width: 44px;
    height: 9px;

    background-image: url("../../assets/shapes/compositions/outlining-panel-green.svg");
    background-position: center;
    background-size: cover;
  }
}

.decoration--pink {
  &::after {
    width: 228px;
    height: 145px;

    right: -67px;
    bottom: -95px;

    background-image: url("../../assets/shapes/compositions/outlining-panel-pink.svg");
    background-position: center;
    background-size: cover;
  }
}

.decoration--light-pink {
  &::after {
    width: 44px;
    height: 9px;

    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;

    background-image: url("../../assets/shapes/compositions/outlining-panel-light-pink.svg");
  }
}
