@use "../../theme/functions" as f;
@use "../../theme/spacing" as s;
@use "../../theme/color" as c;
@use "../../theme/_typography" as t;
@import "../../_theme.scss";

.scroll-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &--bottom-shadowed {
    &::before {
      display: block;
      position: absolute;
      z-index: 3;
      bottom: 0;
      right: -1px;
      left: -1px;
      height: $spacing-s3;
      background: linear-gradient(
        180deg,
        rgba($primary-white, 0) 0%,
        rgba($primary-rich-blue, 0.08) 100%
      );
      content: "";
    }
  }
}

.scroll-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tooltip {
  position: absolute;
  left: 50%;
  bottom: s.$spacing-s3;
  cursor: pointer;
  z-index: 2;
  width: fit-content;

  composes: body--13-350 from "../../theme/typography.module.scss";

  > div {
    position: relative;
    left: -50%;

    border: 1px solid c.$primary-ph-blue;
    border-radius: 25px;
    padding: 0 s.$spacing-s3;
    color: c.$primary-ph-blue;
    font-size: t.$font-size-13;
    background-color: c.$primary-powder-blue-light;
  }
}
