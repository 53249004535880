/** @prettier */
@use "../../../../../theme/spacing";

.wrapper {
  display: flex;
  align-items: center;
  gap: spacing.$spacing-s2;
}

.label--underline {
  text-decoration: underline;
}
