/* WARNING, ACHTUNG! AUTOGENERATED FILE */
/* Your changes to this file will perish */
@use "sass:math";

// Primary
$primary-white: #ffffff;
$primary-rich-blue: #00061a;
$primary-dark-blue: #0b38d5;
$primary-ph-blue: #2757ff;
$primary-powder-blue: #d6e6ff;
$primary-powder-blue-light: #eaf2ff;
$primary-grey-600: #555555;
$primary-grey-500: #86868b;
$primary-grey-400: #cfcfcf;
$primary-grey-300: #e7e7e7;
$primary-grey-200: #f2f2f2;
$primary-grey-100: #f8f8f8;
$primary-red: #8a140c;
$primary-red-light: #f0dbdb;
$primary-yellow: #ddc911;
$primary-yellow-light: #f7f2be;
$primary-green: #0b693d;
$primary-bright-green: #00a141;
$primary-olive: #bac97e;
$primary-olive-light: #ebf0db;

// Transparent
$transparent-white-70: rgba(#ffffff, 0.7);
$transparent-rich-blue-70: rgba(#00061a, 0.7);
