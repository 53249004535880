@use "~dsl/src/theme/_color.scss" as color;

.header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid color.$primary-grey-300;
  padding: 16px 32px;
  background-color: color.$primary-white;
}

.header > :not(:last-child) {
  margin-right: 40px;
}

.right-side {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

.right-side {
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  place-items: center;
}
