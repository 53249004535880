@use "~dsl/src/theme/_color.scss" as color;
@use "src/ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  composes: (body--15-350);

  margin: 0 8px 0 0;
  line-height: normal;
}

.portal {
  width: 300px;
  padding: 16px;
  border-radius: variables.$border-radius;
  position: absolute;
  left: 0;
  z-index: map-get(variables.$z-index, picker);
  background-color: color.$primary-white;
  box-shadow: variables.$box-shadow;
  border: 1px solid color.$primary-grey-300;
}
