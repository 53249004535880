$primary-font-family: ABCFavoritVariable;

$icon-size--xs: 12px;
$icon-size--s: 16px;
$icon-size: 20px;
$icon-size--l: 24px;
$icon-size--xl: 28px;

$border-radius: 4px;
$border-radius--l: 8px;

$overlay-color: rgba(0, 6, 26, 0.2);
$box-shadow: 0 6px 20px rgba(47, 47, 47, 0.1);

$z-index: (
  globalDropzone: 10000,
  modal: 9000,
  menu: 8000,
  tooltip: 7000,
  tabOverlay: 6500,
  cameraControls: 6000,
  sceneUi: 5500,
  sceneOverlay: 5000,
  picker: 1000,
);

$hover-transition: 250ms ease-in-out;
