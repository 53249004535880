@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.button {
  width: 100%;
  padding: 14px;
  border: 1px solid color.$primary-grey-100;
  border-radius: variables.$border-radius;
  background-color: color.$primary-grey-100;
  cursor: pointer;
  line-height: 1;
  transition: variables.$hover-transition;
}

.normal {
  composes: (header--17-400);
}

.light {
  composes: (body--15-350);
}

.button:not(:disabled):hover,
.button.active {
  border: 1px solid var(--color-primary, color.$primary-ph-blue);
}

.button:disabled {
  color: color.$primary-rich-blue;
  cursor: default;
}

.button svg {
  margin-right: 8px;
}
