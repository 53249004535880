.wrapper {
  max-width: 100%;
  max-height: 100%;
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.wrapper.dieline {
  position: absolute;
}

.wrapper.active {
  display: block;
}

.wrapper > div {
  max-width: 100%;
  max-height: 100%;
}
