@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;

.dropdown {
  min-width: 148px;
  padding: 4px;
  box-sizing: border-box;
  border-radius: variables.$border-radius;
  background-color: color.$primary-white;
  box-shadow: variables.$box-shadow;
}
