@import "../../_theme.scss";

.base {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.with-spacing {
  margin-top: $spacing-s4;
}

.legend {
  composes: header--15-500 from "../../theme/typography.module.scss";
  margin-bottom: $spacing-s2;
  display: flex;
}

.label {
  font-weight: $font-weight-medium;
  color: $font-color-black;
  cursor: pointer;

  > span {
    font-weight: $font-weight-regular;
  }
}

.hint {
  display: flex;
  color: $font-color-grey-600;
  margin: 0 $spacing-s1;
  user-select: none;
}

.link {
  float: right;
}

.subtitle {
  composes: body--13-350 from "../../theme/typography.module.scss";

  display: inline-block;
  width: 100%;

  user-select: none;
  color: $font-color-grey-600;
}

.error {
  composes: body--13-350 from "../../theme/typography.module.scss";

  display: inline-block;
  width: 100%;
  margin-top: $spacing-s2;

  color: $font-color-red;

  &.no-margin {
    margin: 0;
  }
}

.mobile-flex {
  @include breakpoint-mobile() {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;

    .legend {
      width: 100%;
    }

    div:nth-child(2) {
      width: 60%;
    }

    .error {
      width: 40%;
      margin-top: 12px;
      padding-left: $spacing-s2;
    }
  }
}
