/** @prettier */
@use "../../../../theme/spacing";
@use "../../../../theme/color";

.dropdown {
  position: absolute;
  top: spacing.$spacing-s7;
  left: 0;
  width: 100%;
  margin-top: spacing.$spacing-s2;
  border: color.$primary-grey-400 1px solid;
  border-radius: 2px;
  box-shadow: 0px 5px 5px #2f2f2f33;
  box-sizing: border-box;
  background-color: color.$primary-white;
  z-index: 3;

  &--hidden {
    display: none;
  }

  &--consise {
    min-width: 380px;
  }

  &--up {
    top: unset;
    bottom: calc(100% + #{spacing.$spacing-s3});
  }
}

.footer {
  border-top: color.$primary-grey-400 1px solid;
}
