@use "../../../theme/spacing" as spacing;
@use "../../../theme/color" as color;
@use "../../../theme/breakpoints" as breakpoint;
@use "../../../theme/indexes" as index;

$product-page-max-width: 1264px;

$modal-custom-spacing: 128px;

$side-modal-width: 1024px;
$side-modal-btn-padding: 12px;
$side-modal-btn-icon-size: 16px;
$side-modal-btn-position: calc(
  -1 * (2 * #{$side-modal-btn-padding} + #{$side-modal-btn-icon-size} + #{spacing.$spacing-s2})
);
$side-modal-mobile-top: 56px;

$layout-xs-width: 488px;
$layout-sm-width: 504px;
$layout-md-width: 764px;
$layout-lg-width: 1280px;
$layout-product-page-width: calc(
  #{$product-page-max-width} + 2 * #{spacing.$spacing-s4}
);

.body-modal-opened {
  overflow: hidden !important;
}

.wrapper {
  align-items: center;
  background-color: rgba(color.$primary-rich-blue, 0.2);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: index.$index-modal;

  @include breakpoint.breakpoint-mobile {
    padding: 0;
  }

  &--top-spacing-md {
    padding-top: spacing.$spacing-s4;
  }

  &--top-spacing-lg {
    padding-top: spacing.$spacing-s7;
  }

  &--top-spacing-x-lg {
    padding-top: 64px;
  }

  &--side-modal {
    justify-content: flex-end;

    .inner {
      justify-content: flex-end;
    }

    @include breakpoint.breakpoint-md {
      padding-top: $side-modal-mobile-top;
    }

    .layout {
      width: $side-modal-width;
      height: 100vh;
      padding: spacing.$spacing-s6;
      overflow-x: hidden;
      overflow-y: auto;

      @include breakpoint.breakpoint-md {
        width: 100vw;
        height: calc(100vh - #{$side-modal-mobile-top});
      }

      @include breakpoint.breakpoint-mobile {
        padding: spacing.$spacing-s3;
      }
    }

    .close-btn {
      position: absolute;
      top: spacing.$spacing-s2;
      left: $side-modal-btn-position;
      background-color: color.$primary-white;
      padding: $side-modal-btn-padding;
      border-radius: 50%;

      @include breakpoint.breakpoint-md {
        top: $side-modal-btn-position;
        right: spacing.$spacing-s2;
        left: unset;
      }
    }
  }

  &--plain-modal {
    .close-btn {
      position: absolute;
      top: spacing.$spacing-s4;
      right: spacing.$spacing-s4;
    }
  }
}

.inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &--align-left {
    justify-content: flex-start;
  }

  &--align-right {
    justify-content: flex-end;
  }

  &--content-right {
    max-width: 1278px;
    justify-content: flex-end;
  }

  &--content-left {
    justify-content: flex-start;
    max-width: 1278px;
  }

  @include breakpoint.breakpoint-md {
    width: 100vw;
    height: 100vh;
  }
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid color.$primary-grey-400;
  box-sizing: border-box;
  display: flex;
  margin-bottom: spacing.$spacing-s4;
  padding-bottom: spacing.$spacing-s4;
  position: relative;

  grid-row: header;
}

.modal-header--empty {
  border-bottom: 0;
  margin-bottom: 0;
  flex-direction: row-reverse;

  button {
    position: absolute;
  }
}

.modal-header--no-border {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.modal-header__content {
  flex: 1 1 100%;
}

.modal-content {
  height: 100%;
}

.header--center {
  padding: 0 (spacing.$spacing-s3 * 2) spacing.$spacing-s4;
  text-align: center;

  @include breakpoint.breakpoint-mobile {
    padding: 0 0 spacing.$spacing-s3;
  }
}

.close-btn {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  z-index: 2;

  &--align-top {
    align-self: flex-start;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.modal-footer {
  border-top: 1px solid color.$primary-grey-400;
  box-sizing: border-box;
  margin-top: spacing.$spacing-s4;
  width: 100%;

  grid-row: footer;

  @include breakpoint.breakpoint-mobile {
    background-color: color.$primary-white;
    margin-top: 0;
    position: sticky;
    top: 100%;
    width: 100%;

    &::after {
      background-color: color.$primary-white;
      bottom: -#{spacing.$spacing-s3};
      content: "";
      display: block;
      height: spacing.$spacing-s3;
      position: absolute;
      width: 100%;
    }
  }
}

.modal-footer--sticky {
  position: sticky;
  top: 100%;
}

.footer--with-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 0;
  padding-top: spacing.$spacing-s3;

  @include breakpoint.breakpoint-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  fill: color.$primary-rich-blue;
  font-size: 16px;
  height: 1em;
  width: 1em;
}

.layout {
  width: 100%;
  max-width: 100%;

  padding: spacing.$spacing-s4;
  position: relative;

  display: flex;
  flex-direction: column;
}

.layout--large-padding-lr {
  padding-left: 128px;
  padding-right: 128px;

  @include breakpoint.breakpoint-mobile {
    padding: spacing.$spacing-s4 spacing.$spacing-s4 spacing.$spacing-s3;
  }
}

.layout--no-padding {
  padding: unset;
}

.layout--sm-padding {
  padding: spacing.$spacing-s4 spacing.$spacing-s4 spacing.$spacing-s3;
}

.layout--full-height {
  height: 100vh;
}

.layout--full-width {
  width: 100vw;
}

.layout--xs {
  width: $layout-xs-width;
}

.layout--sm {
  width: $layout-sm-width;
}

.layout--md {
  width: $layout-md-width;
}

// compared to sm and md, lg's width is higher than breakpoint-mobile (which triggers fullscreen modals) so it needs to be treated differently
.layout--lg {
  max-width: 100vw;
  width: $layout-lg-width;
}

.layout--sm,
.layout--md,
.layout--lg {
  @include breakpoint.breakpoint-mobile {
    width: 100vw;
    height: 100%;
  }
}

.layout--xl {
  width: calc(100vw - 2 * #{spacing.$spacing-s5});
  max-width: $layout-product-page-width;
  padding: spacing.$spacing-s4;

  @include breakpoint.breakpoint-mobile {
    width: auto;
    max-width: 100%;
    padding: spacing.$spacing-s3;
  }
}

.modal-splash-header {
  background-color: color.$primary-grey-400;
  &--white-four {
    background-color: color.$primary-grey-100;
  }
}

.modal-splash-header--fixed {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal-splash-header--z-index {
  z-index: 2;
}
