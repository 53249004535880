@import "../../../_theme.scss";
$modal-width-mobile: 360px;
$modal-width: 504px;
$modal-width-medium: 764px;
$modal-width-large: 854px;
$modal-width-big: 1280px;
$modal-base-width: 1024px;
$modal-width-base: $modal-base-width;

$modal-content-bottom-distance: $spacing-s4;

$modal-custom-spacing: 128px;

.body-modal-opened {
  overflow: hidden !important;
}

.wrapper {
  //  1px is for a border overlap; --header-height is global!
  // by default it will be 100vh, starting from top: 0 🙌
  --wrapper-offset: calc(var(--header-height, 1px) - 1px);

  position: fixed;
  z-index: $index-modal;

  height: calc(100vh - var(--wrapper-offset));

  top: var(--wrapper-offset);
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  box-sizing: border-box;

  background-color: rgba($primary-grey-400, 0.95);

  @include breakpoint-mobile {
    align-items: flex-start;

    padding-top: $spacing-s4;
  }

  @include breakpoint-xs {
    padding: 0;
  }
}

.wrapper--mobile {
  padding-top: $spacing-s4;
  align-items: flex-start;
}

.inner {
  width: 100%;
  max-width: $modal-width;

  @include breakpoint-xs {
    max-width: unset;
  }

  @include animation(fade-in, 1);
}

.inner--mobile {
  max-width: $modal-width-mobile;
}

.inner--medium {
  max-width: $modal-width-medium;
}

.inner--large {
  max-width: $modal-width-large;
}

.inner--big {
  max-width: $modal-width-big;
}

.inner--base {
  max-width: $modal-width-base;
}

.full-height--without-margins {
  --wrapper-margins: 0px;
}

.full-height--with-margins {
  // We need to set height: calc(100vh - #{$spacing-s3 * 2}) in additional wrapper.
  // The class with grid definition has to have height in % to avoid bug in Safari
  // which causes whole content to jump when user clicks on Select inside.
  // Also - dont cap it with max-height; if you need other height than that,
  // you should probably use a different modal type
  --wrapper-margins: #{$spacing-s3 * 2};
}

.full-height-wrapper {
  height: calc(100vh - var(--wrapper-margins));
  overflow: visible;
}

.base {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  padding: $spacing-s4 $spacing-s4 $spacing-s3;

  color: $font-color-black;

  box-shadow: 0 10px 10px 0 rgba($primary-rich-blue, 0.2);

  @include breakpoint-mobile {
    max-width: unset;
    padding: $spacing-s3;
  }

  @include breakpoint-xs {
    display: flex;
    flex-flow: column nowrap;

    min-height: calc(100vh - var(--wrapper-offset) - 2px);
  }

  &--wide-sides {
    padding: $spacing-s4 $modal-custom-spacing $spacing-s3;

    @include breakpoint-mobile {
      max-width: unset;
      padding: $spacing-s3;
    }

    .close {
      position: absolute;
      z-index: 2;
      top: ($spacing-s6 - $spacing-s2);
      right: $modal-custom-spacing;

      display: flex;
      justify-content: center;
      align-items: center;

      box-sizing: border-box;
      padding: 0;

      cursor: pointer;

      border: 0;
      background-color: transparent;

      @include breakpoint-mobile {
        top: $spacing-s4;
        right: $spacing-s3;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.base--full-height {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  grid-template-areas:
    "header"
    "main"
    "footer";

  height: 100%;
  overflow: hidden;

  .header {
    grid-area: header;

    margin-bottom: 0;
  }

  .content {
    grid-area: main;

    overflow-y: auto;

    margin-bottom: 0;
  }

  .footer {
    grid-area: footer;

    margin-top: 0;

    z-index: 2;
  }
}

.inner--bare .base {
  padding: 0;
}

.inner--bare .content {
  margin-bottom: 0;
}

// Top

.header {
  box-sizing: border-box;
  margin-bottom: $spacing-s4;
  padding: 0 ($spacing-s3 * 2) $spacing-s4 0;

  border-bottom: 1px solid $primary-grey-400;

  @include breakpoint-mobile {
    padding: 0 0 $spacing-s3;
  }
}

.header--center {
  text-align: center;
  padding: 0 ($spacing-s3 * 2) $spacing-s4;

  @include breakpoint-mobile {
    padding: 0 0 $spacing-s3;
  }
}

.close {
  position: absolute;
  z-index: 2;
  top: 32px;
  right: $spacing-s4;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  padding: 0;

  cursor: pointer;

  border: 0;
  background-color: transparent;

  @include breakpoint-mobile {
    top: $spacing-s4;
    right: $spacing-s3;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.icon {
  width: 1em;
  height: 1em;

  font-size: 16px;
  fill: currentColor;
}

// Content

.content {
  width: 100%;

  margin-bottom: $modal-content-bottom-distance;

  @include breakpoint-xs {
    flex: 1 0 100%;
  }
}

.content--no-margin {
  margin: 0;
}

.content--padding-xsmall {
  padding-top: $spacing-s4;
}

.content--padding-small {
  padding-top: $spacing-s6;
}

.content--padding-medium {
  padding-top: $spacing-s7;
}

.content--padding-big {
  padding-top: $spacing-s8;
}

.content--padding-large {
  padding-top: $spacing-s9;
}

// Bottom

.footer {
  box-sizing: border-box;
  width: 100%;
  margin-top: $spacing-s4;
  padding-top: $spacing-s3;

  border-top: 1px solid $primary-grey-400;

  /**
    Sometimes it's much, much easier to render the footer within the content.
    In those cases we need to remove the bottom margin from content to remove doubling of it!
  */
  .content & {
    margin-bottom: -#{$modal-content-bottom-distance};
  }

  @media (max-width: #{$breakpoint-mobile-lg}px) and (orientation: portrait) {
    position: sticky;
    bottom: $spacing-s3;

    width: 100%;

    padding-bottom: $spacing-s3;
    margin-top: 0;

    background-color: $primary-white;

    &:after {
      content: "";
      display: block;

      width: 100%;
      height: $spacing-s3;
      background-color: $primary-white;

      position: absolute;
      bottom: -#{$spacing-s3};
    }
  }
}

.footer--with-buttons {
  display: grid;
  padding-top: $spacing-s3;
  padding-bottom: 0;

  grid-template-columns: repeat(2, 1fr);

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

.footer--with-shadow {
  @media (max-width: #{$breakpoint-mobile-lg}px) and (orientation: portrait) {
    &:before {
      content: "";

      position: absolute;
      top: -$spacing-s3;
      right: -1px;
      left: -1px;

      display: block;
      height: $spacing-s3;

      background: linear-gradient(
        180deg,
        transparent 0%,
        rgba($primary-rich-blue, 0.08) 100%
      );
    }
  }
}

.col {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;

  grid-row: 1 / 2;
}

.col--left {
  justify-content: flex-start;

  @include breakpoint-mobile {
    justify-content: center;
  }

  grid-column: 1 / 2;
}

.col--right {
  justify-content: flex-end;

  @include breakpoint-mobile {
    justify-content: center;
    margin-bottom: $spacing-s3;
  }

  grid-column: 2 / 3;

  /**
    Sometimes it's much, much easier to render the footer within the content.
    In those cases we need to remove the bottom margin from content to remove doubling of it!
  */
  .content & {
    @include breakpoint-mobile {
      margin-bottom: 0;
    }
  }
}

.col--span-all {
  grid-column: 1 / -1;
}

.full-height {
  height: 100%;
}

.align__left {
  position: absolute;
  top: 0;
  left: 0;
}

.align__right {
  position: absolute;
  top: 0;
  right: 0;
}
