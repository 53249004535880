@use "~dsl/src/theme/_color.scss" as color;
@use "../../../_settings/variables" as variables;

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color.$primary-grey-100;
}

.wrapper.bg {
  background-image: url("../../../../assets/_images/dby-scene-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.preview {
  max-width: 80%;
  max-height: 80%;
  box-shadow: variables.$box-shadow;
}

.preview img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
