@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  height: 44px;
  padding: 2px;
  display: flex;
  box-sizing: border-box;
  background-color: color.$primary-grey-200;
  border-radius: 99px;
}
