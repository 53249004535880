@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/_spacing.scss" as spacing;
@use "~dsl/src/theme/typography.module.scss";

.input-underline {
  composes: (body--20-350);

  width: 100%;
  height: 32px;
  box-sizing: border-box;
  padding-bottom: 12px;
  border: 0;
  border-bottom: 1px solid color.$primary-grey-300;
  outline: transparent;
  line-height: normal;
}

.input-underline:not(:disabled):hover,
.input-underline:focus {
  border-bottom-color: color.$primary-grey-400;
}

.input-underline:disabled {
  color: color.$primary-rich-blue;
  background: none;
}
