@use "~dsl/src/theme/_spacing.scss" as spacing;
@use "~dsl/src/theme/_color.scss" as color;

.wrapper {
  background-color: transparent;
}

.colorsList {
  max-height: 255px;
}

.noResults {
  display: flex;
  color: color.$primary-rich-blue;
  padding: spacing.$spacing-s3 0;
  > * {
    margin: auto;
  }
}

.divider {
  margin-block-start: spacing.$spacing-s3;
  margin-block-end: spacing.$spacing-s2;
  margin-block: 0;
  width: 100%;
  border: none;
  border-top: 1px solid color.$primary-grey-400;
}
