@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  padding-top: 32px;
  border-top: 1px solid color.$primary-grey-300;
}

.title {
  composes: (header--17-400);

  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1;
}

.title svg {
  margin-right: 8px;
}

.title svg use {
  fill: color.$primary-green;
}

.content {
  margin-top: 16px;
  display: grid;
  gap: 8px;
}
