@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper {
  position: relative;
  padding: 24px 110px 24px 24px;
  box-sizing: border-box;
  border-radius: variables.$border-radius--l;
  border: 1px solid color.$primary-grey-200;
  background-color: color.$primary-grey-100;
  overflow: hidden;
}

.wrapper.active {
  border-color: var(--color-primary, color.$primary-ph-blue);
}

.title {
  composes: (header--17-400);

  margin: 0 0 8px;
}

.description {
  composes: (body--13-350);

  margin: 0 0 24px;
  color: color.$primary-grey-600;
}

.button {
  composes: (body--13-350);

  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: color.$primary-grey-600;
  transition: variables.$hover-transition;
}

.button:hover {
  color: color.$primary-rich-blue;
}

.button svg {
  margin-right: 8px;
}

.button svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
  transition: variables.$hover-transition;
}

.button:hover svg use {
  fill: color.$primary-rich-blue;
}

.wrapper > img {
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.price {
  margin-left: 4px;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
}
