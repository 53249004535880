/** @prettier */
@use "../../../../theme/color" as color;
@use "../../../../theme/spacing" as spacing;

$mobile-option-height: 56px;

.option {
  padding: 0 spacing.$spacing-s2;
  height: spacing.$spacing-s6;
  display: flex;
  align-items: center;
  transition: background-color 250ms;
  border-radius: spacing.$spacing-s1;
  margin: 0 spacing.$spacing-s2 spacing.$spacing-s1;

  &:focus,
  &--matching {
    outline: none;
    background-color: color.$primary-grey-100;
  }

  &:hover {
    cursor: pointer;
    background-color: color.$primary-grey-100;
  }

  &--disabled,
  &--disabled:hover {
    cursor: default;
    background-color: color.$primary-grey-400;
    color: color.$primary-grey-500;
  }

  &--mobile {
    padding: 0 spacing.$spacing-s3;
    height: $mobile-option-height;
    border: 1px solid color.$primary-grey-400;
    margin: 0 spacing.$spacing-s3 spacing.$spacing-s2;

    &.option--selected {
      border-color: color.$primary-dark-blue;
    }

    &:focus,
    &.option--matching {
      outline: none;
      background-color: color.$primary-white;
    }
  }
}
