@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

.content {
  composes: (body--15-350);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content svg {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
}

.content svg use {
  fill: var(--color-primary, color.$primary-ph-blue);
}
