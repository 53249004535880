/** @prettier */
@use "../../../../theme/spacing";
@use "../../../../theme/color";

.scroll-wrapper {
  flex: 1;
}

.scroll {
  max-height: 240px;

  &--mobile {
    max-height: 0;
  }

  &--consise {
    max-height: 160px;
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacing.$spacing-s2;
  color: color.$primary-grey-500;
}

.list {
  width: 100%;
  list-style: none;
  padding: spacing.$spacing-s2 0;
  margin: 0;

  &--empty {
    padding: 0;
  }
}
