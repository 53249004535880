@use "src/ui/dsl/_settings/variables" as variables;
@use "~dsl/src/theme/_color.scss" as color;

.overlay,
.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get(variables.$z-index, menu);
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: variables.$overlay-color;
  display: none;
  opacity: 0;
  animation: fadeOut 0.35s ease-in-out;
}

.overlay.visible {
  display: block;
  opacity: 1;
  animation: fadeIn 0.35s ease-in-out;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

.menu {
  width: 380px;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: color.$primary-white;
  display: none;
  left: -380px;
  animation: slideOut 0.35s linear;
}

.menu.open {
  display: flex;
  left: 0;
  animation: slideIn 0.35s linear;
}

@keyframes slideIn {
  0% {
    display: none;
    left: -380px;
  }

  1% {
    display: flex;
    left: -380px;
  }

  100% {
    display: flex;
    left: 0;
  }
}

@keyframes slideOut {
  0% {
    display: flex;
    left: 0;
  }

  100% {
    display: none;
    left: -380px;
  }
}

.header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid color.$primary-grey-300;
}

.content {
  display: grid;
  gap: 16px;
  padding: 24px 32px;
}

.footer {
  margin-top: auto;
  padding: 24px 32px;
}
