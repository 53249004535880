@use "../../../../_settings/variables" as variables;

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 248, 248, 0.8);
  border-radius: variables.$border-radius;
}
