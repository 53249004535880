@use "~dsl/src/theme/_spacing.scss" as spacing;
@use "~dsl/src/theme/_color.scss" as color;

$item-width: 40px;
$horizontal-spacing: spacing.$spacing-s3;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list {
  width: 100%;
  min-width: 300px;
  overflow: auto;
  padding-inline-start: 0;
  margin-block: 0;
  margin-block-start: spacing.$spacing-s1;
  padding-top: spacing.$spacing-s3;
}

$inset: 2px;
.colorItem {
  display: inline-block;
  margin: $inset;
  margin-bottom: spacing.$spacing-s3;
  &:not(:nth-of-type(6n)) {
    margin-right: $horizontal-spacing;
  }
  &:nth-of-type(6n) {
    // scroll bar always visible
    margin-right: $horizontal-spacing; // spacing.$spacing-s2;
  }
  width: calc(#{$item-width} - #{$inset});
}

.colorItemInnerWrapper {
  // overide button styles and style accessiblity
  appearance: none;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  vertical-align: top;
  &:active {
    outline: none;
    border: none;
    transform: none;
    vertical-align: top;
  }
  &:hover {
    box-shadow: 0 0 0 2px rgba(50, 50, 50, 0.15);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-primary, color.$primary-ph-blue);
  }
  &:active {
    outline: none;
    border: none;
    transform: none;
    vertical-align: top;
  }
  cursor: pointer;
  // now styling as casual div
  width: $item-width;
  height: $item-width;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.colorItemInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: center;
  color: black;
  border-style: solid;
  border-width: 2px;
}

.textWrapper {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  color: color.$primary-grey-600;
  text-align: center;
}
.textWrapperSelected {
  composes: textWrapper;
  color: color.$primary-rich-blue;
}
.checkMark {
  height: 16px;
  position: relative;
  top: 0;
  left: 8px;
}
.checkMarkWhite {
  composes: checkMark;
  fill: color.$primary-white;
}
.checkMarkGrey {
  composes: checkMark;
  fill: color.$primary-grey-500;
}
