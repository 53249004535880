@use "../../dsl/_settings/variables" as variables;

.ui {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-areas:
    "toolbar toolbar"
    "nav notifications"
    "camera camera";
  grid-template-rows: minmax(0, auto) 1fr 100px;
  z-index: map-get(variables.$z-index, sceneUi);
}

.ui > :not(.floating-cta) {
  position: relative;
}

.ui > :not(.disabled) > * {
  pointer-events: all;
}

.floating-cta {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translate(-50%, 0);
}

.toolbar {
  grid-area: toolbar;
}

.nav {
  grid-area: nav;
  padding: 20px;
}

.notifications {
  grid-area: notifications;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.camera {
  grid-area: camera;
  display: flex;
  justify-content: center;
  padding: 24px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
