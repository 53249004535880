@use "~dsl/src/theme/typography.module.scss";

.label {
  composes: (body--15-350);

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px;
  white-space: nowrap;
  line-height: normal;
}

.label:first-child {
  padding-left: 16px;
}

.label:last-child {
  padding-right: 16px;
}
