@import "../../_theme.scss";

.wrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.wrapper--hidden-radio {
  pointer-events: none;
}

.wrapper--fluid {
  width: 100%;
}
