@use "~dsl/src/theme/_color.scss" as color;
@use "../../_settings/variables" as variables;
@use "~dsl/src/theme/typography.module.scss";

.wrapper.horizontal {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px -8px 0;
}

.wrapper.horizontal > * {
  margin: 0 8px 8px 0;
}

.wrapper.vertical > * {
  width: 100%;
  display: block;
  text-align: left;
}

.wrapper.vertical > :not(:last-child) {
  margin-bottom: 8px;
}
