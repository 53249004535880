@import "../../_theme.scss";
$size-input-large: 64px;
$size-input-medium: 48px;
$size-input-small: 40px;
$size-input-xsmall: 32px;
$size-menu-max-height: 200px;
$size-arrow: 14px;
$size-price-width: 70px;

@keyframes pulsating-glow {
  0% {
    border-color: $primary-ph-blue;
    box-shadow: 0 0 4px $primary-ph-blue;
  }
  100% {
    border-color: $primary-grey-300;
    box-shadow: 0 0 20px $primary-white;
  }
}

// Base

.base {
  position: relative;

  display: block;

  width: 100%;
  &:hover:not(.is-disabled):not(.is-error) {
    .input {
      border-color: $primary-dark-blue;
    }
    .controller-button {
      color: $primary-ph-blue;
    }
  }
}

// Placeholder

.placeholder {
  position: relative;

  width: 100%;
  padding: 0;

  text-align: left;

  color: $primary-rich-blue;
  border: 0;
  background-color: transparent;
  &:focus,
  &:active {
    outline: none;
  }

  .details {
    padding-right: 0;
  }
}

// Input

.input {
  composes: body--15-350 from "../../theme/typography.module.scss";

  display: block;

  box-sizing: border-box;
  width: 100%;
  padding: 0 $spacing-s3;

  cursor: pointer;
  transition: 250ms ease-in-out;

  border: 1px solid $primary-grey-300;
  border-radius: 4px;
  background: $primary-white;
}

.is-opened .input {
  border-color: $primary-ph-blue;
  box-shadow: 0 0 4px $primary-dark-blue;
}

.is-error .input {
  border-color: $primary-red;
  background-color: $primary-red-light;
}

.is-disabled .input {
  cursor: default;

  color: $primary-grey-500;
  border-color: $primary-grey-300;
  background-color: $primary-grey-100;
}

.input--fake {
  display: flex;
  align-items: center;

  padding: 0 $spacing-s5 0 $spacing-s3;
}

.input--xsmall {
  height: $size-input-xsmall;
}

.input--small {
  height: $size-input-small;
}

.input--medium {
  min-height: $size-input-medium;
}

.input--large {
  min-height: $size-input-large;
}

.input--with-pulsating-glow {
  @include animation(pulsating-glow, 0.8, ease-out, infinite, alternate);
}

.input--with-error {
  background-color: $primary-red-light;
  border-color: $primary-red;
}

// Menu

.menu-outer-wrap {
  color: $primary-rich-blue;
}

.menu-wrap {
  position: absolute;
  top: 100%;

  display: none;

  width: 100%;
  min-width: 380px;
  margin: 0;
  margin-top: $spacing-s2;

  @include breakpoint-mobile() {
    min-width: 260px;
  }

  &.is-opened {
    z-index: 10;

    display: block;
  }
}

.menu-outer-wrap--fluid .menu-wrap {
  min-width: unset;

  @include breakpoint-mobile() {
    min-width: unset;
  }
}

.menu-outer-wrap--auto-width .menu-wrap {
  min-width: unset;
  width: unset;

  @include breakpoint-mobile() {
    min-width: unset;
    width: unset;
  }
}

.menu-outer-wrap--mobile-fluid .menu-wrap {
  @include breakpoint-xs() {
    min-width: calc(100vw - #{$spacing-s3} * 2);
  }
}

.menu-wrap--up {
  top: auto;
  bottom: 100%;

  margin-top: 0;
  margin-bottom: $spacing-s2;
}

.menu {
  overflow-x: hidden;
  overflow-y: scroll;

  max-height: $size-menu-max-height;
  padding: $spacing-s2;

  ul {
    margin: 0;
    padding: 0;
  }

  .item {
    cursor: pointer;
  }
}

.item {
  position: relative;

  display: flex;
  align-items: center;
  flex-grow: 1;

  box-sizing: border-box;
  padding: $spacing-s2 0 $spacing-s2 $spacing-s2;

  transition: 250ms ease-in-out;

  border-radius: 4px;
  &.is-active,
  &.is-selected {
    background: $primary-grey-100;
  }

  &.is-disabled {
    cursor: default;
    color: $primary-grey-500;
    background-color: $primary-grey-400;
  }

  &:hover {
    &.no-background {
      background: initial;
    }
  }
}

.item--placeholder {
  padding: ($spacing-s2 - 1) 0;

  overflow: hidden;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: normal;
  }
}

// Items content

.text-wrap {
  user-select: none;
}

.thumb-item-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.thumb-wrap {
  display: flex;
}

.thumb-wrap + .text-wrap {
  margin-left: $spacing-s2;
}

.description {
  composes: body--13-350 from "../../theme/typography.module.scss";

  color: $primary-grey-500;
}

.details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: $spacing-s6;
  flex-grow: 1;
}

.price,
.badge {
  display: inline-block;

  vertical-align: middle;
}

.price {
  composes: header--15-500 from "../../theme/typography.module.scss";
  width: auto;
  font-weight: $font-weight-medium;
  text-align: right;
}

.item-with-tag-wrap {
  display: flex;
  justify-content: flex-start;

  > span {
    position: relative;
    top: 2px;
  }

  .badge {
    margin-left: $spacing-s1;
  }

  &--space-between {
    width: 100%;
    justify-content: space-between;
  }
}

// Search

.search-wrap {
  width: 100%;
}

// Button

.controller-button {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: $spacing-s7 - 1;
  height: 100%;

  cursor: pointer;
}

.is-opened .controller-button {
  color: $primary-ph-blue;
}

.is-error .controller-button {
  color: $primary-red;
}

.is-disabled .controller-button {
  cursor: default;

  color: $primary-grey-400;
}

.controller-button-img {
  width: 1em;
  height: 1em;

  font-size: $size-arrow;

  transition: 250ms ease-in-out;

  fill: currentColor;
}

.is-opened .controller-button-img {
  transform: rotateZ(180deg);
}

.menu-hidden {
  display: none;
}

// render an input element which can be focused but is hidden
.focusable-element {
  opacity: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
  position: fixed;
  left: -1000vmax;
  top: -1000vmax;
}

.base:not(.is-error) .focusable-element:focus {
  & ~ .placeholder .input {
    border-color: $primary-ph-blue;
    box-shadow: 0 0 4px $primary-ph-blue;
  }
}
