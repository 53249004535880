.wrapper {
  display: none;
  gap: 32px;
  place-content: start;
  grid-template-columns: 1fr;
}

.wrapper.visible {
  display: grid;
}
