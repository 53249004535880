@import "../../_theme.scss";
$size-checkbox-large: 24px;
$size-checkbox-small: 16px;
$size-checkbox-mobile: 32px;
$size-tick-large: 16px;
$size-tick-small: 8px;
$size-tick-mobile: 16px;

.wrapper {
  position: relative;

  display: flex;
  flex-shrink: 0;

  box-sizing: border-box;
  width: $size-checkbox-large;
  height: $size-checkbox-large;

  @include breakpoint-mobile {
    width: $size-checkbox-mobile;
    height: $size-checkbox-mobile;
  }
  * {
    box-sizing: border-box;
  }
}

.wrapper--small {
  width: $size-checkbox-small;
  height: $size-checkbox-small;

  @include breakpoint-mobile {
    width: $size-checkbox-mobile;
    height: $size-checkbox-mobile;
  }
}

.wrapper--right {
  order: 2;

  margin-left: $spacing-s2;
}

.wrapper--left {
  margin-right: $spacing-s2;
}

.mark {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  transition: 250ms ease-in-out;

  color: transparent;
  border: 1px solid $primary-grey-400;
  border-radius: 4px;
  background-color: $primary-white;
}

.error {
  border-color: $primary-red;
  &.checked {
    color: $primary-red;
    background-color: $primary-white;
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  margin: 0;

  cursor: pointer;

  opacity: 0;
  border: 0;

  appearance: none;
  &:not(:disabled):hover + .mark {
    box-shadow: 0 2px 2px rgba($primary-rich-blue, 0.2);
    border-color: var(--color-primary, $primary-ph-blue);
    &.error {
      border-color: $primary-red;
    }
  }
  &:checked + .mark {
    color: $primary-white;
    border-color: var(--color-primary, $primary-ph-blue);
    background-color: var(--color-primary, $primary-ph-blue);
    &.error {
      color: $primary-red;
      border-color: $primary-red;
      background-color: $primary-white;
      &:hover {
        border-color: $primary-red;
      }
    }
  }
  &:disabled {
    pointer-events: none;
  }
  &:disabled + .mark {
    pointer-events: none;

    border-color: $primary-grey-400;
    background-color: $primary-grey-100;

    svg,
    path {
      fill: $primary-grey-100;
    }

    &.checked {
      color: $primary-grey-400;

      svg,
      path {
        fill: $primary-grey-500;
      }
    }
  }

  &:focus + .mark {
    border-color: var(--color-primary-dark, $primary-dark-blue);
    box-shadow: 0 2px 2px 0 rgba(var(--color-primary, $primary-ph-blue), 0.2);
    &.error {
      border-color: $primary-red;
    }
  }
}

.tick {
  width: 1.5em;
  height: 1em;

  font-size: $size-tick-large;

  @include breakpoint-mobile {
    font-size: $size-tick-large;
  }

  fill: currentColor;
}

.wrapper--small .tick {
  font-size: $size-tick-small;

  @include breakpoint-mobile {
    font-size: $size-tick-mobile;
  }
}

@include breakpoint-mobile {
  .wrapper + span {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: $spacing-s1 0;
  }
}
