@import "../../_theme.scss";
$size-stepper: 16px;

.base {
  display: flex;

  box-sizing: border-box;
  width: $size-stepper;
  height: $size-stepper;

  border: 1px solid $primary-grey-400;
  border-radius: 100%;
  background-color: $primary-white;
}

.current {
  border: 4px solid $primary-green;

  &.base--lightish-blue {
    border-color: $primary-ph-blue;
  }
}

.completed {
  background-size: 8px;
  background-color: $primary-green;
  border-color: $primary-green;
  display: flex;
  justify-content: center;
  align-items: center;

  &.base--lightish-blue {
    background-color: $primary-ph-blue;
    border-color: $primary-ph-blue;
  }

  .icon {
    fill: $primary-white;
    width: 8px;
  }
}
