@use "~dsl/src/theme/_color.scss" as color;
@use "~dsl/src/theme/typography.module.scss";

$border-color-dark: rgba(134, 134, 139, 0.4);
$border-color-light: rgba(248, 248, 248, 0.4);

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ruler {
  composes: (body--15-350);

  display: flex;
  align-items: center;
  position: absolute;
  color: color.$primary-grey-500;

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 11px;
    margin-top: 11px;
  }

  > span {
    flex: none;
    padding: 0 10px;
    white-space: nowrap;
  }
}

.ruler.light {
  color: color.$primary-grey-100;
}

.ruler.dashed {
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-top: 1px dashed $border-color-dark;
    height: 11px;
    margin-top: 11px;
  }

  &::before {
    border-left: 1px dashed $border-color-dark;
  }

  &::after {
    border-right: 1px dashed $border-color-dark;
  }
}

.ruler.dashed.light {
  &::before,
  &::after {
    border-top-color: $border-color-light;
  }

  &::before {
    border-left-color: $border-color-light;
  }

  &::after {
    border-right-color: $border-color-light;
  }
}
